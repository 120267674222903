import styles from "./MessageList.styles.module.scss";
import { useCallback, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import { selectUser } from "../../../../../store/slices/authSlice";
import {
  fetchChatPartner,
  fetchKnowledgeHints,
  fetchPageContextData,
  updateNeedsOperatorStatus,
} from "../../supabaseRequests";
import { getData } from "../../../../../service/supabase.js";
import { Modal } from "../../../../../components/Modal";
import { Typography } from "../../../../../components";
import userAvatar from "../../../../../assets/img/user.png";
import { getChannelIcon } from "../../../../../utils";
import Switch from "react-switch";
import { useChatContext } from "../../context/ChatContextProvider";
import { Logo, Send } from "../../../../../assets/icons";
import MessageItem from "./components/MessageItem";
import {
  useSendInstagramMessageMutation,
  useSendOLXAccountMessageMutation,
  useSendTelegramMessageMutation,
  useSendViberMessageMutation,
  useSendWpWidgetMessageMutation,
  useSendOcWidgetMessageMutation,
  useSendSwWidgetMessageMutation,
} from "../../../../../store/api";
import supabase from "../../../../../supabase";

const MessageList = () => {
  const user = useSelector(selectUser);
  const { chatPartnerId } = useParams();
  const { setActiveTab, setIsNeedUpdateChatList, isNeedUpdateChatList } =
    useChatContext();

  const [chatPartner, setChatPartner] = useState(null);
  const [pageContext, setPageContext] = useState("");
  const [messagesList, setMessagesList] = useState(null);
  const [knowledgeHints, setKnowledgeHints] = useState([]);
  const [specMessageData, setSpecMessageData] = useState(null);

  const [isShowModal, setIsShowModal] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [activePulling, setActivePulling] = useState(true);
  const [botName, setBotName] = useState("");

  const chatListRef = useRef(null);
  const intervalRef = useRef(null);
  const textareaRef = useRef(null);

  const [sendInstagramMessage] = useSendInstagramMessageMutation();
  const [sendWpWidgetMessage] = useSendWpWidgetMessageMutation();
  const [sendOcWidgetMessage] = useSendOcWidgetMessageMutation();
  const [sendSwWidgetMessage] = useSendSwWidgetMessageMutation();
  const [sendOLXAccountMessage] = useSendOLXAccountMessageMutation();
  const [sendTelegramMessage] = useSendTelegramMessageMutation();
  const [sendViberMessage] = useSendViberMessageMutation();

  const channelsWithSendingMessages = {
    Instagram: {
      sendMessageFunction: sendInstagramMessage,
    },
    WpWidget: {
      sendMessageFunction: sendWpWidgetMessage,
    },
    OcWidget: {
      sendMessageFunction: sendOcWidgetMessage,
    },
    SwWidget: {
      sendMessageFunction: sendSwWidgetMessage,
    },
    "OLX Account": {
      sendMessageFunction: sendOLXAccountMessage,
    },
    Messenger: {
      sendMessageFunction: sendInstagramMessage,
    },
    Telegram: {
      sendMessageFunction: sendTelegramMessage,
    },
    Viber: {
      sendMessageFunction: sendViberMessage,
    },
  };

  const getChatPartner = useCallback(async () => {
    try {
      const { data: activeChatData, error: activeChatError } =
        await fetchChatPartner(user.id, chatPartnerId);

      if (activeChatError) {
        toast.error("Error fetching active chat: " + activeChatError.message);
        return;
      }

      if (activeChatData) {
        const { assistant_id, page_id, communication_channel } = activeChatData;

        // get bot name
        const { data: assistantsData, error: assistantsError } = await getData(
          "assistants",
          "bot_name",
          "id",
          assistant_id
        );

        if (assistantsError) {
          toast.error(
            "Error fetching assistant data: " + assistantsError.message
          );
          return;
        }

        const botName = assistantsData?.[0]?.bot_name || "";
        setBotName(botName);

        // Get channels data
        const { data: channelData, error: channelError } = await supabase
          .from("channels")
          .select("*")
          .eq("account_id", user.id)
          .eq("assistant_id", assistant_id);

        if (channelError) {
          toast.error("Error fetching channel data: " + channelError.message);
          return;
        }

        if (!channelData || channelData.length === 0) {
          toast.error("No channel data found");
          return;
        }

        // Find the corresponding channel by page_id
        const channel = channelData.find((ch) => ch.page_id === page_id);

        let pageDescription = page_id;

        if (channel && channel.page_description) {
          pageDescription = channel.page_description;
        }

        setChatPartner({
          ...activeChatData,
          page_description: pageDescription,
        });
      }
    } catch (error) {
      toast.error("Unexpected error: " + error.message);
    }
  }, [chatPartnerId, user.id]);

  useEffect(() => {
    if (chatPartnerId && user) {
      getChatPartner();
    }
  }, [chatPartnerId, getChatPartner, user]);

  useEffect(() => {
    const restoreScrollPosition = () => {
      if (chatListRef.current) {
        chatListRef.current.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }
    };
    setTimeout(restoreScrollPosition, 300);
  }, [messagesList?.length, isTyping]);

  const fetchChatMessages = useCallback(async () => {
    try {
      const chatHistoryUrl = chatPartner.chat_history_url;
      const chatId = chatPartner.id;

      const [chatHistoryResponse, pageContextResponse] = await Promise.all([
        axios.get(chatHistoryUrl),
        fetchPageContextData(chatId),
      ]);

      const fetchedMessages = chatHistoryResponse.data.length
        ? chatHistoryResponse.data
            .split("\n")
            .filter((line) => line.trim() !== "")
            .map((line) => JSON.parse(line))
            .filter(
              (message) =>
                message.role !== "function" &&
                (message.content !== "" || message.MultiContent)
            )
        : [chatHistoryResponse.data];

      const pageContextData = pageContextResponse.data;
      if (pageContextData) {
        setPageContext(pageContextData.page_context);
      }

      setMessagesList(fetchedMessages);
    } catch (error) {
      toast.error("Oops, some error. Please contact the administrator");
    }
  }, [chatPartner]);

  useEffect(() => {
    if (isNeedUpdateChatList) {
      fetchChatMessages();
      setIsNeedUpdateChatList(false);
    }
  }, [isNeedUpdateChatList, fetchChatMessages, setIsNeedUpdateChatList]);

  const fetchSpecialMessages = useCallback(async () => {
    try {
      const { data, status } = await fetchKnowledgeHints(user.id);

      if (data && status === 200) {
        setKnowledgeHints(data);
      }
    } catch (error) {
      toast.error(error);
    }
  }, [user]);

  useEffect(() => {
    if (!activePulling) return clearInterval(intervalRef.current);
    if (chatPartner) {
      fetchChatMessages();
      fetchSpecialMessages();
      intervalRef.current = setInterval(() => {
        fetchChatMessages();
      }, 15000);
    }

    return () => intervalRef.current && clearInterval(intervalRef.current);
  }, [
    activePulling,
    chatPartner,
    fetchChatMessages,
    fetchSpecialMessages,
    user,
  ]);

  const handleToggleSwitch = async () => {
    try {
      setIsLoading(true);

      const needs_operator = !chatPartner.needs_operator;

      const { error } = await updateNeedsOperatorStatus(
        chatPartner.id,
        needs_operator
      );

      setIsNeedUpdateChatList(true);
      getChatPartner();
      setActiveTab(needs_operator ? 1 : 0);

      if (error) {
        throw new Error("Failed to turn off channel");
      }
    } catch (error) {
      setErrorText(error.message);
      setIsShowModal(true);
    } finally {
      setIsLoading(false);
    }
  };

  const sendSpecMessageData = async () => {
    try {
      setIsSending(true);

      const data = await axios.post(
        `${process.env.REACT_APP_API_URL}/improve-knowledge?account=${user.id}`,
        specMessageData
      );

      if (data.status === 200) {
        fetchSpecialMessages();
        fetchChatMessages();
      }

      toast.success("Knowledge added, active immediately.");
    } catch (error) {
      //console.log(error);
      toast.error(
        specMessageData.knowledge + " - this " + error.response.data.error
      );
    } finally {
      setIsSending(false);
    }
  };

  const handleChangeSpecMessageTextarea = (msgId, value) => {
    setSpecMessageData({
      message_id: msgId,
      knowledge: value.target.value.trim(),
      chat_id: chatPartner.id,
    });
  };

  const handleClickSpecMessageSave = () => {
    if (!specMessageData || specMessageData?.knowledge?.length === 0) {
      setErrorText("Please enter text, the field must NOT be empty");
      setIsShowModal(true);
      return;
    }
    sendSpecMessageData();
  };

  const handleClickSpecMessageIcon = (messageId) => {
    setActivePulling(false);
    setSpecMessageData(null);

    const updateMessages = messagesList.map((msg) => {
      if (msg.id === messageId) {
        if (msg?.knowledge) {
          setActivePulling(true);
          return {
            ...msg,
            knowledge: false,
          };
        } else {
          return {
            ...msg,
            knowledge: true,
          };
        }
      } else {
        return {
          ...msg,
          knowledge: false,
        };
      }
    });

    setMessagesList(updateMessages);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleSend = async () => {
    try {
      if (!isTyping && inputValue.trim() !== "") {
        if (chatListRef.current) {
          chatListRef.current.scrollIntoView({
            behavior: "smooth",
            block: "end",
          });
        }

        setIsTyping(true);

        const newMessage = {
          communicationChannel: chatPartner.communication_channel,
          clientID: chatPartner.client_id,
          accountID: chatPartner.account_id,
          pageID: chatPartner.page_id,
          content: inputValue.trim(),
        };

        const sendMessageFunction =
          channelsWithSendingMessages[chatPartner.communication_channel]
            .sendMessageFunction;

        const { error: errorApi } = await sendMessageFunction(newMessage);

        if (errorApi) {
          setIsShowModal(true);
          setErrorText(errorApi?.data || "Error sending message");
          setIsTyping(false);
        } else {
          // After successful sending immediately update the list of messages
          await fetchChatMessages();
          setIsTyping(false);
        }
      }

      setInputValue("");
    } catch (error) {
      toast.error("Сталася помилка при відправленні повідомлення.");
      setIsTyping(false); // In case of an unforeseen error, stop the indicator
    }
  };

  return (
    chatPartner &&
    messagesList && (
      <div className={styles.chat}>
        <div className={styles.userChatHeader}>
          <div className={styles.headerContainer}>
            <div className={styles.clientInfo}>
              *
              <img
                src={chatPartner.client_avatar_url || userAvatar}
                alt="avatar"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = userAvatar;
                }}
              />
              <div className={styles.clientData}>
                {chatPartner.page_description && (
                  <div className={styles.pageID}>
                    {chatPartner.page_description}
                  </div>
                )}

                <div className={styles.client}>
                  <div className={styles.clientName}>
                    {chatPartner.client_name ||
                      chatPartner.client_username ||
                      chatPartner.client_id}
                  </div>
                  <div className={styles.clientData}>
                    {getChannelIcon(chatPartner.communication_channel)}

                    <div style={{ marginLeft: "6px", fontSize: "1.25rem" }}>
                      {chatPartner.needs_operator
                        ? `(AI ${botName} is off)`
                        : `(Talking with AI ${botName})`}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {pageContext && (
              <div
                style={{ fontSize: "1.25rem" }}
                className={styles.pageContext}
              >
                {pageContext}
              </div>
            )}
            <div className={styles.operatorOrAISwitch}>
              <p>AI</p>
              <Switch
                disabled={isLoading}
                onChange={handleToggleSwitch}
                checked={!chatPartner.needs_operator}
                uncheckedIcon={false}
                checkedIcon={false}
                onColor={"#3588e9"}
              />
            </div>
          </div>
          <div className={styles.chatList} ref={chatListRef}>
            {(messagesList.length === 0 || !messagesList[0]) && (
              <div
                className={styles.containerMessage}
                style={{ marginBottom: "15px" }}
              >
                <div className={`${styles.message} ${styles.assistant}`}>
                  <Typography variant="h3" className={styles.subTitle}>
                    Messages will appear soon...
                  </Typography>
                </div>
              </div>
            )}
            {messagesList &&
              messagesList[0] &&
              messagesList.map((message, index) => (
                <MessageItem
                  key={message.id || message.created_at}
                  handleClickSpecMessageIcon={handleClickSpecMessageIcon}
                  knowledgeHints={knowledgeHints}
                  message={message}
                  isSending={isSending}
                  handleClickSpecMessageSave={handleClickSpecMessageSave}
                  handleChangeSpecMessageTextarea={
                    handleChangeSpecMessageTextarea
                  }
                  setActivePulling={setActivePulling}
                />
              ))}

            {isTyping && (
              <div className={styles.containerTyping}>
                <div className={styles.botResponse}>
                  <Logo />
                  <p>Sending...</p>
                </div>
              </div>
            )}
          </div>
          {Object.keys(channelsWithSendingMessages).includes(
            chatPartner.communication_channel
          ) && (
            <div className={styles.wrapper}>
              <textarea
                className={styles.chatInput}
                ref={textareaRef}
                rows={1}
                placeholder={"Type your message..."}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyPress}
              />
              <div
                onClick={handleSend}
                className={`${styles.icon} ${
                  isTyping || inputValue === "" ? styles.active : ""
                }`}
              >
                <Send />
              </div>
            </div>
          )}
          <Modal
            title="Error"
            description={errorText}
            setIsShowModal={setIsShowModal}
            isShowModal={isShowModal}
          />
        </div>
      </div>
    )
  );
};

export default MessageList;
