import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import {
  fetchIntegrations,
  selectAvailableIntegrations,
  selectSelectedAssistant,
  selectSelectedIntegrationsIdsByType,
  selectSelectedIntegrationType,
} from "../../../../../../store/slices/integrationSlice";
import {
  useIntegrationTokenMutation,
  useValidateIntegrationAssetsMutation,
} from "../../../../../../store/api";
import supabase from "../../../../../../supabase";
import useUpdateAssistantIntegrations from "../../../../useUpdateAssistantIntegrations";
import { fetchInstructions as fetchAssistants } from "../../../../../../store/slices/assistantSlice";
import { useLoadingContext } from "../../../../useLoadingContext";

const useAuthConnectionData = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isLoading, setIsLoading } = useLoadingContext();

  const {
    addAssistantIntegration,
    currentTypeAssistantIntegrationId,
    removeAssistantIntegration,
  } = useUpdateAssistantIntegrations();
  const selectedIntegrationType = useSelector(selectSelectedIntegrationType);
  const selectedIntegrationsIdsByType = useSelector(
    selectSelectedIntegrationsIdsByType
  );
  const selectedAssistant = useSelector(selectSelectedAssistant);
  const availableIntegrations = useSelector(selectAvailableIntegrations);
  const user = useSelector((state) => state.auth.user);
  const [integrationToken] = useIntegrationTokenMutation();
  const [validateIntegrationAssets] = useValidateIntegrationAssetsMutation();
  const dispatch = useDispatch();

  const selectedAssistantIntegration = useMemo(() => {
    const selectedIntegrationId = selectedAssistant?.integrations?.find((id) =>
      selectedIntegrationsIdsByType.includes(id)
    );
    return availableIntegrations?.find(
      (integration) => integration.id === selectedIntegrationId
    );
  }, [selectedAssistant, selectedIntegrationsIdsByType, availableIntegrations]);

  const sheetIdUrl = useMemo(() => {
    return selectedAssistantIntegration?.sheet_id
      ? `https://docs.google.com/spreadsheets/d/${selectedAssistantIntegration.sheet_id}/edit`
      : null;
  }, [selectedAssistantIntegration]);

  const isAssistantConnected = useMemo(() => {
    if (
      !selectedAssistant ||
      !selectedIntegrationType ||
      !selectedAssistant?.integrations
    ) {
      return false;
    }

    return selectedAssistant?.integrations?.some((integrationId) =>
      selectedIntegrationsIdsByType.includes(integrationId)
    );
  }, [selectedAssistant, selectedIntegrationType, availableIntegrations]);

  const getIntegrationToken = async (auth_code) => {
    try {
      setIsLoading(true);

      let updatedIntegrations = null;
      if (currentTypeAssistantIntegrationId) {
        updatedIntegrations = await removeAssistantIntegration(
          currentTypeAssistantIntegrationId
        );
      }

      const resp = await integrationToken({
        account_id: user.id,
        integration: selectedIntegrationType?.name,
        auth_code: auth_code,
        knowledge_base: selectedIntegrationType?.knowledgeBase,
      });

      if (resp.error) {
        toast.error(resp?.error?.data?.error);
      } else {
        const responseDataId = resp.data.id;
        await addAssistantIntegration(responseDataId, updatedIntegrations);
      }
    } catch (error) {
      toast.error(error?.data?.error || error.message);
    } finally {
      await dispatch(fetchIntegrations());
      await dispatch(fetchAssistants());
      setIsLoading(false);
    }
  };

  const login = useGoogleLogin({
    scope: selectedIntegrationType?.scope,
    flow: "auth-code",
    onSuccess: async (codeResponse) => {
      await getIntegrationToken(codeResponse.code);
    },
  });

  const handleUserConsent = (consent) => {
    setIsModalOpen(false);
    if (consent) {
      login();
    }
  };

  const validateIntegration = async (sheetId) => {
    try {
      setIsLoading(true);
      const resp = await validateIntegrationAssets({
        account_id: user.id,
        integration: selectedIntegrationType?.name,
        access_token: selectedAssistantIntegration?.access_token,
        sheet_id: sheetId,
      });

      if (resp.error) {
        toast.error(resp.error.data ? resp.error.data.error : resp.error);
      } else {
        toast.success(resp.data);
      }
    } catch (error) {
      toast.error(error?.data?.error || error.message);
    } finally {
      dispatch(fetchIntegrations());
      setIsLoading(false);
    }
  };

  const updateIntegrationAssets = (updateObject) => {
    try {
      return supabase.from("integrations").update(updateObject).match({
        account_id: user.id,
        access_token: selectedAssistantIntegration?.access_token,
        id: selectedAssistantIntegration?.id,
      });
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const pickerCallback = async (data) => {
    try {
      if (data.action === "picked") {
        const docId = data.docs[0].id;
        const updateObject = { sheet_id: docId };
        await validateIntegration(docId);
        await updateIntegrationAssets(updateObject);
        dispatch(fetchIntegrations());
      }
    } catch ({ message }) {
      toast.error(message);
    }
  };

  const handleOpenPicker = () => {
    const integrationTitle = selectedIntegrationType?.name;
    let view;
    switch (integrationTitle) {
      case "Spreadsheet Inventory":
      case "Spreadsheet Orders":
        view = window.google.picker.ViewId.SPREADSHEETS;
        break;
      default:
        view = window.google.picker.ViewId.DOCUMENTS;
    }

    const showPicker = () => {
      // TODO(developer): Replace with your API key
      const picker = new window.google.picker.PickerBuilder()
        .setAppId("14528528059-6fds545h4jtt5lr1tm88bp6i2bh73g1k")
        .addView(view)
        .setOAuthToken(selectedAssistantIntegration?.access_token)
        .setDeveloperKey(process.env.REACT_APP_GOOGLE_PICKER_API_KEY)
        .setCallback(pickerCallback)
        .build();
      picker.setVisible(true);
    };
    showPicker();
  };

  return {
    selectedIntegrationType,
    isModalOpen,
    setIsModalOpen,
    handleUserConsent,
    isLoading,
    showFilePicker:
      selectedAssistantIntegration && !selectedAssistantIntegration?.sheet_id,
    handleOpenPicker,
    isAssistantConnected,
    sheetIdUrl,
  };
};

export default useAuthConnectionData;
