import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import supabase from "../../../../../../supabase";
import { toast } from "react-toastify";
import {
  exchangeBitrixCodeForTokens,
  fetchIntegrations,
} from "../../../../../../store/slices/integrationSlice";
import { fetchInstructions as fetchAssistants } from "../../../../../../store/slices/assistantSlice";
import useUpdateAssistantIntegrations from "../../../../useUpdateAssistantIntegrations";

export const useBitrixCodeExchange = (user, selectedAssistant) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const hasExchangedCode = useRef(false);
  const { addAssistantIntegration } = useUpdateAssistantIntegrations();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const stateParam = params.get("state");
    const domain = params.get("domain");

    if (code && domain && !hasExchangedCode.current) {
      hasExchangedCode.current = true;

      let userId = user.id;
      let assistantId = selectedAssistant?.id || null;
      let integrationId = null;

      if (stateParam) {
        const decodedState = decodeURIComponent(stateParam);
        const [stateUserId, stateAssistantId, stateIntegrationId] =
          decodedState.split("|");
        userId = stateUserId;
        assistantId = stateAssistantId;
        integrationId = stateIntegrationId;
      }

      // Dispatch Redux thunk to exchange code for tokens
      dispatch(
        exchangeBitrixCodeForTokens({ code, userId, assistantId, domain })
      )
        .unwrap()
        .then(async (data) => {
          dispatch(fetchIntegrations());
          dispatch(fetchAssistants());

          toast.success("Bitrix integration connected successfully!");
        })
        .catch((error) => {
          console.error("Failed to exchange code for tokens:", error);
          toast.error("Failed to connect Bitrix integration.");
        })
        .finally(() => {
          // Clean up URL parameters
          params.delete("code");
          params.delete("state");
          params.delete("domain");
          navigate(`${location.pathname}?${params.toString()}`, {
            replace: true,
          });
        });
    }
  }, [location.search, dispatch, navigate, user.id, selectedAssistant]);
};
