import React from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {ArrowRightBottom} from '../../../assets/icons';

import styles from './QuickSetUp.module.scss'

import {ROUTES} from "../../../constants/routes";
import {STEPS} from "../../../constants/quickSetup";

export const QuickSetUp = () => {
    const navigate = useNavigate();

    const step = useSelector(state => state.onboarding.step);

    const navigateToSubscription = () => {
        navigate(ROUTES.dashboardPage);
    }

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.text}>Quick set up</div>
            </div>
            <div className={styles.stepContainer}>
                {STEPS.map((i, index) => (
                    <div
                        key={i.name}
                        className={`
                            ${styles.step}
                            ${(step === null && index === 0) || step === i.id ? styles.active : ''}
                            ${i.id === 3 ? styles.sub : ''}
                        `}
                        onClick={i.id === 3 ? navigateToSubscription : undefined}
                    >
                        <div className={styles.step__info}>
                            <div className={styles.stepIcon}>{i.icon}</div>
                            <div className={styles.step__text}>{i.name}</div>
                        </div>
                        <div className={styles.step__arrow}><ArrowRightBottom/></div>
                    </div>
                ))}
            </div>
        </div>
    );
};
