import { Input } from "../../../../../../components";
import React from "react";
import styles from "./WpWidgetInputs.module.scss";
import useCMSWidgetDataContext from "../../useCMSWidgetDataContext";

const WpWidgetInputs = () => {
  const {
    widgetName,
    setWidgetName,
    greetingText,
    setGreetingText,
    buttonText,
    setButtonText,
    isWidgetNameValid,
    setIsWidgetNameValid,
    isDownloaded,
  } = useCMSWidgetDataContext();

  const handleWidgetNameChange = (e) => {
    setWidgetName(e.target.value);
    if (!isWidgetNameValid) {
      setIsWidgetNameValid(false);
    }
  };

  return (
    <div className={styles.inputsContainer}>
      <div>
        <Input
          inputTitle="Widget name"
          placeholder="Plugin for My Site"
          customClass={styles.input}
          value={widgetName}
          onChange={handleWidgetNameChange}
          errorText={isWidgetNameValid ? "" : "This field is required"}
          disabled={isDownloaded}
        />
      </div>
      {/*<div>*/}
      {/*  <Input*/}
      {/*    inputTitle="First greeting message"*/}
      {/*    placeholder="Hello! Let's talk :)"*/}
      {/*    customClass={styles.input}*/}
      {/*    value={greetingText}*/}
      {/*    onChange={(e) => setGreetingText(e.target.value)}*/}
      {/*    disabled={isDownloaded}*/}
      {/*  />*/}
      {/*</div>*/}
      <div>
        <Input
          inputTitle="Widget element pop-up text"
          placeholder="Сontact a manager"
          customClass={styles.input}
          value={buttonText}
          onChange={(e) => setButtonText(e.target.value)}
          disabled={isDownloaded}
        />
      </div>
    </div>
  );
};

export default WpWidgetInputs;
