import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateData } from "../../service/supabase";
import { fetchNotifications } from "../../store/slices/notificationsSlice";

const useNotificationData = () => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const markAsRead = useCallback(
    async (id) => {
      await updateData(
        "notifications",
        { status: "seen" },
        {
          id,
          account_id: user.id,
        }
      );
      toast("Notification marked as read");
      await dispatch(fetchNotifications());
    },
    [user]
  );

  const removeNotification = useCallback(
    async (id) => {
      await updateData(
        "notifications",
        {
          is_removed: true,
          "status": "seen",
        },
        {
          id,
          account_id: user.id,
        }
      );
      toast("Notification removed");
      await dispatch(fetchNotifications());
    },
    [user]
  );

  return {
    markAsRead,
    removeNotification,
  };
};

export default useNotificationData;
