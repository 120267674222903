import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import supabase from "../../supabase";

const initialState = {
  notifications: [],
};

export const fetchNotifications = createAsyncThunk(
  "notifications/fetchNotifications",
  async (_, { getState }) => {
    const userId = getState().auth.user.id;

    const { data } = await supabase
      .from("notifications")
      .select("*")
      .eq("account_id", userId)
      .eq("is_removed", false);

    return data;
  }
);

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotifications: (state, action) => {
      state.notifications = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
    });
  },
});

export const selectAllNotifications = (state) =>
  state.notifications.notifications;

export default notificationsSlice.reducer;
