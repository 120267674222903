import AuthModal from "./AuthModal";
import useAuthConnectionData from "./useAuthConnectionData";
import { Button, Typography } from "../../../../../../components";
import { useSubscription } from "../../../../../SubscriptionPage/useSubscription";
import styles from "./AuthConnection.module.scss";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../../../store/slices/authSlice";
import { normalizeSubscriptionPlan } from "../../../../../../helper";

const AuthConnection = () => {
  const {
    selectedIntegrationType,
    isModalOpen,
    setIsModalOpen,
    handleUserConsent,
    showFilePicker,
    handleOpenPicker,
    isAssistantConnected,
    sheetIdUrl,
  } = useAuthConnectionData();

  const user = useSelector(selectUser);
  const { subscriptionPlan } = useSubscription(user);
  const normalizedPlan = normalizeSubscriptionPlan(subscriptionPlan);

  return (
    <div>
      {sheetIdUrl ? (
        <Typography variant="caption2" className={styles.editSheet}>
          You can edit your spreadsheet{" "}
          <a href={sheetIdUrl} target="_blank" rel="noreferrer">
            here
          </a>
        </Typography>
      ) : null}
      <AuthModal
        isOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleUserConsent={handleUserConsent}
      />
      <Typography variant="caption2">
        Connect your account to {selectedIntegrationType?.name}
      </Typography>
      <Button
        variant="contained"
        title={isAssistantConnected ? "Reconnect" : "Connect"}
        onClick={() => setIsModalOpen(true)}
        className={styles.button}
        disabled={normalizedPlan === null}
      />
      {showFilePicker ? (
        <>
          <Typography variant="caption2">
            You can finish connection by selecting file
          </Typography>
          <Button
            variant="contained"
            title="Open file picker"
            onClick={handleOpenPicker}
            className={styles.button}
          />
        </>
      ) : null}
    </div>
  );
};

export default AuthConnection;
