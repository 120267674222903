import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { openInNewTab } from "../../../utils";

import {
  Burger,
  Channels,
  Chats,
  Configurations,
  Dashboard,
  FollowUp,
  Helpdesk,
  Integrations,
  KnowledgeBase,
  ReferralProgram,
  Subscription,
  Support,
} from "../../../assets/icons";
import logo from "../../../assets/img/logo.png";
import userAvatar from "../../../assets/img/user.png";

import styles from "./MobileNavigator.module.scss";

import { ROUTES } from "../../../constants/routes";
import Notifications from "../Navigation/Notifications/Notifications";
import { useSubscription } from "../../../pages/SubscriptionPage/useSubscription";
import { normalizeSubscriptionPlan } from "../../../helper";

export const MobileNavigator = () => {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const { subscriptionPlan } = useSubscription(user);
  const normalizedPlan = normalizeSubscriptionPlan(subscriptionPlan);

  const isSubscriptionActive = normalizedPlan !== null && normalizedPlan !== "";

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleBurgerMenuClick = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const getLinkClassName = (route, requiresSubscription) => {
    let className = `${styles.navigation}`;
    if (location.pathname === route) {
      className += ` ${styles.navigationActive}`;
    }
    if (!isSubscriptionActive && requiresSubscription) {
      className += ` ${styles.nonActive}`;
    }
    return className;
  };

  return (
    <header className={styles.mobileHeader}>
      <div className={styles.burgerMenu} onClick={handleBurgerMenuClick}>
        <Burger />
      </div>
      <div className={styles.logoContainer}>
        <img src={logo} alt="" loading={"lazy"} />
      </div>
      {isSidebarOpen && (
        <>
          <div
            className={styles.sidebarBackdrop}
            onClick={() => {
              setIsSidebarOpen(false);
            }}
          />
          <div className={styles.sidebar}>
            <div className={styles.header}>
              <img src={logo} alt="" loading={"lazy"} />
            </div>
            <div className={styles.container}>
              <div className={styles.navigationContainer}>
                <div>
                  <Link
                    to={ROUTES.dashboardPage}
                    className={getLinkClassName(ROUTES.dashboardPage, false)}
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <Dashboard />
                    DASHBOARD
                  </Link>
                  <Link
                    to={ROUTES.aiConfigurationPage}
                    className={getLinkClassName(
                      ROUTES.aiConfigurationPage,
                      false
                    )}
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <Configurations />
                    AI Configuration
                  </Link>
                  <Link
                    to={ROUTES.chatsPage}
                    className={getLinkClassName(ROUTES.chatsPage, false)}
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <Chats />
                    Active Chats
                  </Link>
                  <Link
                    to={ROUTES.channelsPage}
                    className={getLinkClassName(ROUTES.channelsPage, false)}
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <Channels />
                    Channels
                  </Link>
                  <Link
                    to={ROUTES.integrationPage}
                    className={getLinkClassName(ROUTES.integrationPage, false)}
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <Integrations />
                    Integrations
                  </Link>
                  <Link
                    to={ROUTES.knowledgeBasePage}
                    className={getLinkClassName(
                      ROUTES.knowledgeBasePage,
                      false
                    )}
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <KnowledgeBase />
                    Knowledge Base
                  </Link>
                  <Link
                    to={ROUTES.followUpPage}
                    className={getLinkClassName(ROUTES.followUpPage, false)}
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <FollowUp />
                    Follow Ups
                  </Link>
                  <Link
                    to={ROUTES.followUpPage}
                    className={getLinkClassName(ROUTES.notifications, false)}
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <Notifications
                      isMobile={true}
                      setIsSidebarOpen={setIsSidebarOpen}
                    />
                  </Link>
                </div>
                <div className={styles.support}>
                  <Link
                    to={ROUTES.subscriptionPage}
                    className={getLinkClassName(ROUTES.subscriptionPage, false)}
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <Subscription />
                    Subscription
                  </Link>
                  <Link
                    to={ROUTES.referralProgramPage}
                    className={getLinkClassName(
                      ROUTES.referralProgramPage,
                      false
                    )}
                    onClick={() => setIsSidebarOpen(false)}
                  >
                    <ReferralProgram />
                    Referral Program
                  </Link>
                  <div className={styles.navigation}>
                    <Support />
                    <a
                      onClick={() =>
                        openInNewTab(`https://mychatbot.app/#form`)
                      }
                    >
                      Support
                    </a>
                  </div>
                  <div className={styles.navigation}>
                    <Helpdesk />
                    <a>Helpdesk</a>
                  </div>
                </div>
              </div>
              <div className={styles.userCard}>
                {user.app_metadata.provider === "email" ? (
                  <Link
                    to={ROUTES.profilePage}
                    onClick={() => {
                      setIsSidebarOpen(false);
                    }}
                    className={`${styles.profile} ${
                      location.pathname === ROUTES.profilePage
                        ? styles.navigationActive
                        : ""
                    }`}
                  >
                    <img src={userAvatar} alt="avatar" />
                    <div className={styles.userCard__userInfo}>
                      <div className={styles.textInfo}>
                        <div className={styles.userCard__email}>
                          {user?.email}
                        </div>
                      </div>
                    </div>
                  </Link>
                ) : (
                  <Link
                    to={ROUTES.profilePage}
                    onClick={() => {
                      setIsSidebarOpen(false);
                    }}
                    className={`${styles.profile} ${styles.socials} ${
                      location.pathname === ROUTES.profilePage
                        ? styles.navigationActive
                        : ""
                    }`}
                  >
                    <img src={user?.user_metadata?.avatar_url} alt="avatar" />
                    <div className={styles.userCard__userInfo}>
                      <div className={styles.textInfo}>
                        <div className={styles.userCard__name}>
                          {user?.user_metadata?.full_name}
                        </div>
                        <div className={styles.userCard__email}>
                          {user?.user_metadata?.email}
                        </div>
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </header>
  );
};
