import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { useSelector } from "react-redux";
import supabase from "../../../../supabase";
import { useRemoveChannelMutation } from "../../../../store/api";
import { useSetupWebhookMutation } from "../../../../store/api";

import { Title } from "../../../../components/Title";
import { Button } from "../../../../components/Button";
import { Loader } from "../../../../components/Loader";
import { Modal } from "../../../../components/Modal";

import styles from "./Messenger.module.scss";

export const Messenger = ({ channelData, fetchChannels, assistantId }) => {
  const channel = channelData[0];
  const user = useSelector((state) => state.auth.user);

  const [isCheck, setIsCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [removeChannel] = useRemoveChannelMutation();
  const [setupWebhook] = useSetupWebhookMutation();

  const handleChange = async () => {
    try {
      setIsLoading(true);
      if (!isCheck) {
        await setupChannelWebhook("Messenger");
      } else {
        const { error } = await supabase
          .from("channels")
          .update({ is_on: false })
          .match({
            account_id: user.id,
            communication_channel: "Messenger",
            assistant_id: assistantId,
          });
        if (error) {
          throw new Error("Failed to change channel status");
        }
      }
      setIsCheck(!isCheck);
    } catch (error) {
      setErrorDescription(error.message);
      setIsModalVisible(true);
    } finally {
      await fetchChannels();
      setIsLoading(false);
    }
  };

  const openMessengerAuth = () => {
    const state = `${user.id}|${assistantId}`;

    window.location.href = `https://www.facebook.com/v17.0/dialog/oauth?client_id=${
      process.env.REACT_APP_FACEBOOK_CLIENT_ID
    }&display=popup&redirect_uri=${encodeURIComponent(
      process.env.REACT_APP_DOMAIN + "/oauth/messenger"
    )}&response_type=token&scope=pages_messaging+pages_show_list+pages_manage_metadata&state=${encodeURIComponent(
      state
    )}`;
  };

  const setupChannelWebhook = async (channelName) => {
    const { error } = await setupWebhook({
      channel: channelName,
      account_id: user.id,
      assistant_id: assistantId,
      page_id: channel.page_id,
    });
    if (error) {
      throw new Error("Failed to setup webhook");
    }
  };

  const revokeToken = async () => {
    try {
      setIsLoading(true);
      const { error: supabaseError } = await supabase
        .from("channels")
        .delete()
        .eq("account_id", user.id)
        .eq("communication_channel", "Messenger")
        .eq("assistant_id", assistantId);

      if (supabaseError) {
        throw new Error("Failed to remove channel");
      }
      await fetchChannels();
    } catch (error) {
      setErrorDescription(error.message);
      setIsModalVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsCheck(channel?.is_on ?? false);
  }, [channel]);

  return (
    <>
      <Title title={"Messenger"} />

      <div className={styles.enableBot}>
        <Switch
          disabled={!channel?.access_token}
          onChange={handleChange}
          checked={isCheck}
          uncheckedIcon={false}
          checkedIcon={false}
          height={27}
          offColor={"#E3E6EE"}
          onColor={"#3588E9"}
        />
        <div className={styles.enableBotText}>
          Connect assistant to this channel
        </div>
      </div>

      <div className={styles.connectionBot}>
        {isLoading ? (
          <div className={styles.loaderContainer}>
            <Loader height={40} width={40} />
          </div>
        ) : (
          <>
            {channel?.access_token && (
              <p className={styles.pageDescription}>
                Page: {channel.page_description}
              </p>
            )}
            {channel?.access_token ? (
              <Button
                onClick={revokeToken}
                className={styles.saveOptimize}
                title={"REVOKE TOKEN"}
              />
            ) : (
              <Button
                onClick={openMessengerAuth}
                className={styles.saveOptimize}
                title={"CONNECT MESSENGER"}
              />
            )}
          </>
        )}
      </div>
      <Modal
        title={"Error"}
        description={errorDescription}
        setIsShowModal={setIsModalVisible}
        isShowModal={isModalVisible}
      />
    </>
  );
};
