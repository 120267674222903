import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLongTermTokenMutation } from "../../store/api";
import { Loader } from "../../components/Loader";
import { setOnboarding } from "../../store/slices/onboardingSlice";
import styles from "./CheckInstagram.module.scss";
import { ROUTES } from "../../constants/routes";
import supabase from "../../supabase";

export const CheckInstagramPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const step = useSelector((state) => state.onboarding.step);

  const [isLoading, setIsLoading] = useState(false);
  const [longTermToken] = useLongTermTokenMutation();
  const hasRequestedToken = useRef(false);

  const handleError = (
    errorDescription = "No pages found for the user",
    error = "Error",
    assistantId = ""
  ) => {
    navigate(`${ROUTES.channelsPage}?assistantId=${assistantId}`, {
      state: {
        channelTab: "Instagram",
        error,
        errorDescription,
      },
    });
  };

  const saveToken = async (data, assistantId) => {
    try {
      if (!assistantId) {
        throw new Error("Assistant ID is not set");
      }

      // Check if a record exists
      const { data: existingChannel } = await supabase
        .from("channels")
        .select("*")
        .eq("account_id", user.id)
        .eq("communication_channel", "Instagram")
        .eq("assistant_id", assistantId)
        .single();

      if (!existingChannel) {
        await supabase.from("channels").insert({
          account_id: user.id,
          access_token: data.page_long_lived_token,
          page_id: data.page_id,
          instagram_id: data.instagram_id,
          page_description: data.page_description,
          communication_channel: "Instagram",
          assistant_id: assistantId,
          is_on: false,
        });
      }

      if (step === 4) {
        await supabase
          .from("onboardings")
          .update({ step: 5 })
          .match({ account_id: user.id });
        dispatch(setOnboarding(5));
      }

      navigate(`${ROUTES.channelsPage}?assistantId=${assistantId}`);
    } catch (error) {
      handleError(error.message, "Error", assistantId);
    }
  };

  const tokenRequest = async () => {
    let assistantId = "";
    try {
      setIsLoading(true);

      const hashParams = new URLSearchParams(window.location.hash.substring(1));
      const accessToken = hashParams.get("access_token");
      const longLivedToken = hashParams.get("long_lived_token");
      const stateParam = hashParams.get("state");

      const decodedState = decodeURIComponent(stateParam);
      const [stateUserId, assistantIdFromState] = decodedState.split("|");
      assistantId = assistantIdFromState;

      if (accessToken && user.id === stateUserId) {
        if (!assistantId) {
          handleError(
            "Assistant ID not found in state parameter",
            "Error",
            assistantId
          );
          return;
        }

        const { error, data } = await longTermToken({
          access_token: accessToken,
          long_lived_token: longLivedToken,
          is_instagram: true,
          assistant_id: assistantId,
        });

        if (error || data.error) {
          handleError(error || data.error, "Error", assistantId);
        } else {
          await saveToken(data, assistantId);
        }
      } else {
        handleError(
          "Invalid access token or state mismatch",
          "Error",
          assistantId
        );
      }
    } catch (error) {
      handleError(error.message, "Error", assistantId);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!hasRequestedToken.current) {
      hasRequestedToken.current = true; // Mark as called
      tokenRequest();
    }
  }, []);

  return (
    isLoading && (
      <div className={styles.loaderContainer}>
        <Loader width={80} height={80} />
      </div>
    )
  );
};
