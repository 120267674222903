import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import { getData } from "../../service/supabase";
import { BG_COLORS } from "../../constants/channels";

const initialState = {
  listOfAssistants: [],
  selectedAssistants: [],
  selectedAssistant: {},
  activeAssistant: null,
  status: "idle",
  error: null,
};

export const fetchInstructions = createAsyncThunk(
  "assistant/fetchInstructions",
  async (userId, { getState }) => {
    const currentUserId = userId ?? getState().auth.user.id;
    const { data } = await getData(
      "assistants",
      "*",
      "account_id",
      currentUserId
    );
    return data;
  }
);

const assistantSlice = createSlice({
  name: "assistant",
  initialState,
  reducers: {
    setSelectedAssistants: (state, action) => {
      state.selectedAssistants = action.payload;
    },
    setSelectedAssistant: (state, action) => {
      state.selectedAssistant = action.payload;
    },
    getActiveAssistant: (state, action) => {
      state.activeAssistant = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstructions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInstructions.fulfilled, (state, action) => {
        state.status = "succeeded";
        const data = action.payload;
        if (data?.length) {
          state.listOfAssistants = data.toSorted((a, b) => a.id - b.id);
        }
      })
      .addCase(fetchInstructions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

const selectAssistantState = (state) => state.assistant;

export const _selectedAssistantId = createSelector(
  [selectAssistantState],
  (state) => state.selectedAssistants?.map(({ value }) => value)
);

export const _updateListOfAssistants = createSelector(
  [selectAssistantState],
  (state) =>
    state.listOfAssistants
      ?.map(({ id, bot_name, status }, index) => ({
        value: id,
        label: bot_name,
        bgColor: BG_COLORS[index],
        status,
      }))
      .toSorted((a, b) => a.value - b.value)
);

export const {
  setSelectedAssistants,
  setSelectedAssistant,
  getActiveAssistant,
} = assistantSlice.actions;

export const selectListOfAssistants = (state) =>
  state.assistant.listOfAssistants;

export default assistantSlice.reducer;
