import styles from "./Chat.styles.module.scss";
import ChatContextProvider from "./context/ChatContextProvider";
import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import ChatList from "./components/ChatList";
import ClientCard from "./components/ClientCard";
import { useEffect, useState } from "react";

const DesktopChatV2 = () => {
  const [queryParams] = useSearchParams();
  const [clientId, setClientId] = useState(null);
  const [checkedClientId, setCheckedClientId] = useState(false);

  useEffect(() => {
    const clientIdParams = queryParams.get("clientid");
    if (clientIdParams) {
      setClientId(clientIdParams);
    } else setClientId(null);
    setCheckedClientId(true);
  }, [queryParams]);

  return (
    <ChatContextProvider>
      {checkedClientId && !clientId ? (
        <div className={styles.chatsContainer}>
          <ChatList />
          <Outlet />
          <ClientCard />
        </div>
      ) : (
        <Navigate to={clientId} />
      )}
    </ChatContextProvider>
  );
};

export default DesktopChatV2;
