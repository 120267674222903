import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";

import { Input } from "../../../../components";
import { useGetAllFollowUpsQuery } from "../../../../store/api";

const AssistantFollowUps = ({ selectedFollowUps, setSelectedFollowUps }) => {
  const user = useSelector((state) => state.auth.user);
  const {
    data: followUps,
    isLoading,
    isError,
  } = useGetAllFollowUpsQuery({ userID: user.id });

  const optimisedOptions = useMemo(() => {
    return followUps?.length > 0
      ? followUps?.map((followUp) => ({
          value: followUp.id,
          label:
            followUp.name.length > 20
              ? followUp.name.slice(0, 20) + "..."
              : followUp.name,
        }))
      : [];
  }, [followUps]);

  const convertedSelectedFollowUps = useMemo(() => {
    return optimisedOptions?.filter((followUp) =>
      selectedFollowUps.includes(Number(followUp.value))
    );
  }, [selectedFollowUps, optimisedOptions]);

  const handleFollowUpsChange = useCallback(
    (selectedOptions) => {
      setSelectedFollowUps(selectedOptions.map((option) => Number(option.value)));
    },
    [setSelectedFollowUps]
  );

  return (
    <Input
      typeInput="select"
      placeholder="Follow up 1, Follow up 2"
      options={optimisedOptions}
      inputTitle="Follow ups"
      value={convertedSelectedFollowUps}
      onChange={handleFollowUpsChange}
      isMulti={true}
      disabled={isLoading || isError || followUps?.length === 0}
      errorText={isError ? "Error fetching data" : ""}
    />
  );
};

export default AssistantFollowUps;
