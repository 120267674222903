import {
  Instagram,
  Messenger,
  Telegram,
  TelegramAccounts,
  Viber,
  WordPress,
  OLX,
  OpenCart,
  StaticWebsiteWidget,
} from "../assets/icons";

export const WP_WIDGET = {
  name: "WP Widget",
  value: "WpWidget",
  id: "WP_WIDGET",
  path: "wp-widget",
};

export const OC_WIDGET = {
  name: "OC Widget",
  value: "OcWidget",
  id: "OC_WIDGET",
  path: "oc-widget",
};

export const SW_WIDGET = {
  name: "Static Website Widget",
  value: "SwWidget",
  id: "SW_WIDGET",
  path: "sw-widget",
};

export const WIDGET_TYPES = {
  WP_WIDGET,
  OC_WIDGET,
  SW_WIDGET,
};

export const CHANNELS = [
  {
    id: 1,
    name: "Telegram",
    value: "Telegram",
    icon: <Telegram />,
    checked: false,
  },
  {
    id: 9,
    name: "Telegram Accounts",
    value: "Telegram Account",
    icon: <TelegramAccounts />,
    checked: false,
  },

  { id: 5, name: "Viber", value: "Viber", icon: <Viber />, checked: false },
  {
    id: 2,
    name: "Instagram",
    value: "Instagram",
    icon: <Instagram />,
    checked: false,
  },
  {
    id: 3,
    name: "Messenger",
    value: "Messenger",
    icon: <Messenger />,
    checked: false,
  },
  {
    id: 4,
    icon: <WordPress />,
    checked: false,
    ...WP_WIDGET,
  },
  {
    id: 6,
    icon: <OpenCart />,
    checked: false,
    ...OC_WIDGET,
  },
  {
    id: 7,
    icon: <StaticWebsiteWidget />,
    checked: false,
    ...SW_WIDGET,
  },
  {
    id: 5,
    name: "OLX Accounts",
    value: "OLX Account",
    icon: <OLX />,
    checked: false,
  },
];

export const BG_COLORS = [
  "#e3e6ee",
  "#E3E6EE",
  "#F3F6F9",
  "#C6C7D3",
  "#EFF2F6",
  "#CDCFDA",
  "#EDF0F5",
  "#E7EAF1",
  "#EBEEF3",
  "#FDFFFF",
  "#F1F4F8",
  "#F5F8FB",
  "#E5E8EF",
  "#F7FAFC",
  "#F9FCFD",
  "#FBFEFE",
  "#FFFFFF",
  "#E3E6EE",
  "#DBDEE7",
  "#D4D7E1",
  "#E9ECF2",
];
