import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Title } from "../Title";
import { Button } from "../Button";
import { IoCheckmark } from "react-icons/io5";
import styles from "./Subscription.module.scss";
import { getButtonProps } from "../../pages/SubscriptionPage/getButtonProps";
import { PopupSubscription } from "../popups/PopupSubscription/PopupSubscription";
import { normalizeSubscriptionPlan } from "../../helper";

export const Subscription = ({
  planData,
  userSubscription,
  cancelPlan,
  upgradePlan,
  highlight,
  isLoading,
}) => {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const user = useSelector((state) => state.auth.user);

  const { title, description, price, businessType, list } = planData;

  const normalizedPlan = userSubscription
    ? normalizeSubscriptionPlan(userSubscription?.subscription_plan)
    : null;

  const getDisplayPlanName = (subscriptionPlan, planTitle) => {
    if (
      subscriptionPlan === "multipro-trial" &&
      planTitle.toLowerCase() === "multipro"
    ) {
      return "Multipro trial";
    }
    return planTitle;
  };

  const buttonProps = getButtonProps(
    userSubscription,
    title.toLowerCase(),
    cancelPlan,
    upgradePlan
  );

  const handleSubscribeClick = () => {
    setIsOpenPopup(true);
  };

  return (
    <div
      className={`${styles.container} ${
        highlight ? styles.highlightMultipro : ""
      }`}
    >
      <div className={styles.headerWrapper}>
        <div className={styles.titleWrapper}>
          <Title
            title={getDisplayPlanName(
              userSubscription?.subscription_plan,
              title
            )}
            className={
              title === "Professional" ? styles.proTitle : styles.title
            }
          />
          <div className={styles.bussinesType}>{businessType}</div>
        </div>
        <div className={styles.priceWrapper}>
          <div className={styles.price}>
            <span>$</span>
            <Title title={`${price}`} />
          </div>
          <span className={styles.perMonth}>/month</span>
        </div>
        <div className={styles.description}>{description}</div>
        {buttonProps && (
          <Button
            className={styles.button}
            onClick={
              buttonProps.action ? buttonProps.action : handleSubscribeClick
            }
            style={buttonProps.style}
            title={buttonProps.title}
            tooltip={buttonProps.tooltip}
            disabled={isLoading}
          />
        )}
      </div>
      <div>
        <div className={styles.featuresList}>
          <ul>
            {list?.map((feature, index) => (
              <li key={index} className={styles.featureItem}>
                <IoCheckmark size={15} color={"#777EF5"} />
                {feature}
              </li>
            ))}
          </ul>
        </div>
        <PopupSubscription
          isOpenPopup={isOpenPopup}
          setIsOpenPopup={setIsOpenPopup}
          userId={user.id}
          subscriptionPlan={title.toLowerCase()}
        />
        {!isLoading &&
          userSubscription &&
          normalizedPlan === title.toLowerCase() &&
          userSubscription.ends_at === null &&
          !userSubscription.cancel_requested && (
            <Button
              onClick={cancelPlan}
              className={styles.button}
              title={"Cancel Plan"}
            />
          )}
        {!isLoading &&
          userSubscription &&
          normalizedPlan === title.toLowerCase() &&
          userSubscription.ends_at === null &&
          userSubscription.cancel_requested && (
            <Button
              onClick={() => {}}
              className={styles.button}
              disabled
              style={{ background: "#d9d9db", color: "#384157" }}
              tooltip={"We will cancel your subscription during 24 hours"}
              title={"Cancel requested"}
            />
          )}
      </div>
    </div>
  );
};
