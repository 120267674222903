import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { clearAltegioToken } from "../../store/slices/integrationSlice";
import supabase from "../../supabase";

import { Title } from "../../components/Title";
import { DeleteAccount } from "../../components/popups/DeleteAccount";
import profile from "../../assets/img/user.png";
import exit from "../../assets/img/exit.png";

import { ROUTES } from "../../constants/routes";

import styles from "./Profile.module.scss";

export const ProfilePage = () => {
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleLogout = async () => {
    try {
      await supabase.auth.signOut();
      dispatch(clearAltegioToken());
      navigate(ROUTES.loginPage);
    } catch (e) {
      toast.error("Error signing out:", e);
    }
  };

  const handleActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const showPopup = () => {
    setIsOpen(true);
  };

  const renderPages = () => {
    return (() => {
      switch (activeTab) {
        case "profile":
          return (
            <>
              <Title title={"Profile"} />
              <div className={styles.profileInfo}>
                <Title title={"Personal Info"} />
                <div>
                  {user.app_metadata.provider === "email" ? (
                    <>
                      <div className={styles.title}>Email</div>
                      <div className={styles.info}>{user?.email}</div>
                    </>
                  ) : (
                    <>
                      <div className={styles.title}>Name</div>
                      <div className={styles.info}>
                        {user?.user_metadata?.full_name}
                      </div>
                      <div className={styles.title}>Email</div>
                      <div className={styles.info}>
                        {user?.user_metadata?.email}
                      </div>
                    </>
                  )}
                  <DeleteAccount
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    handleLogout={handleLogout}
                    userId={user.id}
                  />
                  <button
                    type="button"
                    className={styles.btnDelete}
                    onClick={showPopup}
                  >
                    DELETE MYCHATBOT ACCOUNT
                  </button>
                </div>
              </div>
            </>
          );
        default:
          return null;
      }
    })();
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.profileTab}>
          <div className={styles.profileTitle}>
            <Title title={"Profile and Settings"} />
          </div>
          <div
            className={styles.profileItem}
            onClick={() => {
              handleActiveTab("profile");
            }}
          >
            <img src={profile} alt={""} />
            <div>Profile</div>
          </div>
          <div onClick={handleLogout} className={styles.logout}>
            <img src={exit} alt={""} />
            <div>Exit profile</div>
          </div>
        </div>
        <div className={styles.profile}>{renderPages()}</div>
      </div>
    </div>
  );
};
