import React, { useState } from "react";
import { Input } from "../../Input";
import { Button } from "../../Button";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styles from "./TwoFactorAuthTelegramForm.module.scss";
import {
  getExistingChannel,
  submitVerificationCode,
} from "../../../service/telegramService";
import { useAuthorizationPolling } from "../../../pages/ChannelsPage/components/TelegramAccounts/useAuthorizationPolling";
import { Loader } from "../../Loader";

export const TwoFactorAuthTelegramForm = ({
  assistantId,
  account,
  onAuthorizationSuccess,
}) => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [waitingForAuthorization, setWaitingForAuthorization] = useState(false);
  const user = useSelector((state) => state.auth.user);

  useAuthorizationPolling(
    waitingForAuthorization,
    {
      userId: user.id,
      assistantId,
      phone: account?.metadata?.phone,
      tgAccountName: account?.metadata?.tg_account_name,
    },
    onAuthorizationSuccess
  );

  if (!account) {
    return null;
  }

  const handleCodeSubmit = async () => {
    setLoading(true);
    try {
      const existingChannel = await getExistingChannel(
        user.id,
        assistantId,
        account.metadata?.phone,
        account.metadata?.password,
        account.metadata?.tg_account_name
      );

      if (!existingChannel) {
        throw new Error("Channel not found.");
      }

      const updatedMetadata = {
        ...existingChannel.metadata,
        code,
      };
      delete updatedMetadata.wait_code;

      await submitVerificationCode(
        user.id,
        assistantId,
        account.access_token,
        updatedMetadata
      );

      setWaitingForAuthorization(true);
    } catch (error) {
      console.error("Error submitting verification code:", error);
      toast.error("Error submitting verification code.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className={styles.loaderContainer}>
          <Loader height={40} width={40} />
        </div>
      ) : (
        <form className={styles.telegramConnection}>
          <label htmlFor="code">Enter Verification Code</label>
          <Input
            label="Verification Code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            placeholder="Enter Verification Code"
          />
          <Button
            variant="contained"
            className={styles.connectButton}
            onClick={handleCodeSubmit}
            disabled={loading}
            title={loading ? "Loading..." : "Continue"}
          />
        </form>
      )}
    </>
  );
};
