import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const chatsApi = createApi({
	reducerPath: 'chatsApi',
	baseQuery: fetchBaseQuery({
		baseUrl: process.env.REACT_APP_API_URL,
		credentials: 'include',
	}),
	endpoints: (builder) => ({
		getUnreadStatusMessages: builder.mutation({
			query: ({ userID }) => ({
				url: `/unreads/${userID}`,
				method: 'GET',
				credentials: 'include',
			}),
		}),
		updateUnreadStatusMessages: builder.mutation({
			query: ({ userID, chatID }) => ({
				url: `/unreads/${userID}`,
				method: 'POST',
				credentials: 'include',
				body: { id: `${chatID}` },
			}),
		}),
		sendInstagramMessage: builder.mutation({
			query: (body) => ({
				url: '/instagram-send',
				method: 'POST',
				credentials: 'include',
				body,
			}),
		}),
		sendWpWidgetMessage: builder.mutation({
			query: (body) => ({
				url: '/wp-widget-send',
				method: 'POST',
				credentials: 'include',
				body,
			}),
		}),
		sendOcWidgetMessage: builder.mutation({
			query: (body) => ({
				url: '/oc-widget-send',
				method: 'POST',
				credentials: 'include',
				body,
			}),
		}),
		sendSwWidgetMessage: builder.mutation({
			query: (body) => ({
				url: '/sw-widget-send',
				method: 'POST',
				credentials: 'include',
				body,
			}),
		}),
		sendOLXAccountMessage: builder.mutation({
			query: (body) => ({
				url: '/olx-account-send',
				method: 'POST',
				credentials: 'include',
				body,
			}),
		}),
		sendTelegramMessage: builder.mutation({
			query: (body) => ({
				url: '/telegram-send',
				method: 'POST',
				credentials: 'include',
				body,
			}),
		}),
		sendViberMessage: builder.mutation({
			query: (body) => ({
				url: '/viber-send',
				method: 'POST',
				credentials: 'include',
				body,
			}),
		}),
		resetChatHistory: builder.mutation({
			query: (body) => ({
				url: '/reset-chat-history',
				method: 'POST',
				credentials: 'include',
				body,
			}),
		}),
	}),
})

export const {
	useGetUnreadStatusMessagesMutation,
	useUpdateUnreadStatusMessagesMutation,
	useSendInstagramMessageMutation,
	useSendWpWidgetMessageMutation,
	useSendOcWidgetMessageMutation,
	useSendSwWidgetMessageMutation,
	useSendOLXAccountMessageMutation,
  useSendTelegramMessageMutation,
  useSendViberMessageMutation,
	useResetChatHistoryMutation,
} = chatsApi
