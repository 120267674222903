import { useEffect, useState } from "react";
import styles from "./ClientCardForm.module.scss";
import DropDownMenu from "./components/DropDownMenu";
import supabase from "../../../../../../../supabase";

const ClientCardForm = ({ clientCardData, updateClientData }) => {
  const [needUpdate, setNeedUpdate] = useState(true);
  const [managers, setManagers] = useState(null);
  const [funnelStatuses, setFunnelStatuses] = useState(null);
  const [labels, setLabels] = useState(null);
  const [clients, setClients] = useState(null);

  const sendDataInSB = async (table, key, value) => {
    await supabase
      .from(table)
      .insert([{ account_id: clientCardData.account_id, [key]: value }]);
    setNeedUpdate(true);
  };

  const updateDataInSB = async (table, data) => {
    await supabase
      .from(table)
      .update(data)
      .match({ client_id: clientCardData.client_id });
    updateClientData();
  };

  useEffect(() => {
    if (clientCardData.account_id && needUpdate) {
      const getDataFromSB = async (table) => {
        const { data } = await supabase
          .from(table)
          .select("*")
          .eq("account_id", clientCardData.account_id);
        return data;
      };

      getDataFromSB("managers").then(
        (res) => res && setManagers(res.map((el) => el.manager_name))
      );
      getDataFromSB("funnel_statuses").then(
        (res) => res && setFunnelStatuses(res.map((el) => el.status_name))
      );
      getDataFromSB("labels").then(
        (res) => res && setLabels(res.map((el) => el.label_name))
      );
      getDataFromSB("client_types").then(
        (res) => res && setClients(res.map((el) => el.type_name))
      );
      setNeedUpdate(false);
    }
  }, [clientCardData, needUpdate]);

  return (
    <div className={styles.container}>
      <DropDownMenu
        value={clientCardData.manager || "Select agent"}
        items={managers || []}
        label="Assigned Manager"
        chipBtnText={"Create manager"}
        formBtnAction={(value) =>
          sendDataInSB("managers", "manager_name", value)
        }
        formLabel="Manager Name"
        formBtnLabel="New manager"
        handleSelect={(value) => updateDataInSB("clients", { manager: value })}
      />
      <DropDownMenu
        value={clientCardData.funnel_status || "Select status"}
        items={funnelStatuses || []}
        label="Funnel status"
        chipBtnText={"Create status"}
        formBtnAction={(value) =>
          sendDataInSB("funnel_statuses", "status_name", value)
        }
        formLabel="Funnel Status Name"
        formBtnLabel="New Status"
        handleSelect={(value) =>
          updateDataInSB("clients", { funnel_status: value })
        }
      />
      <DropDownMenu
        label="Labels"
        items={labels || []}
        value={clientCardData.labels || "Select label"}
        chipBtnText={"Create label"}
        formBtnAction={(value) => sendDataInSB("labels", "label_name", value)}
        formLabel="Label"
        formBtnLabel="New Label"
        handleSelect={(value) => updateDataInSB("clients", { labels: value })}
      />
      <DropDownMenu
        value={clientCardData.type || "Select client"}
        items={clients || []}
        label="Client type"
        chipBtnText={"Create client type"}
        formBtnAction={(value) =>
          sendDataInSB("client_types", "type_name", value)
        }
        formLabel="Client type"
        formBtnLabel="New Client type"
        handleSelect={(value) => updateDataInSB("clients", { type: value })}
      />
    </div>
  );
};

export default ClientCardForm;
