import supabase from "../../supabase";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchIntegrations,
  selectSelectedIntegrationType,
} from "../../store/slices/integrationSlice";
import { toast } from "react-toastify";

const useUpdateIntegrationData = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const selectedIntegrationType = useSelector(selectSelectedIntegrationType);

  const deleteIntegration = async (id) => {
    try {
      const { error: errorSupabase } = await supabase
        .from("integrations")
        .delete()
        .eq("account_id", user.id)
        .eq("id", id);

      if (errorSupabase) {
        toast.error(errorSupabase);
      }
    } catch (error) {
      toast(error.data?.message || error?.message);
    } finally {
      dispatch(fetchIntegrations());
    }
  };

  return {
    deleteIntegration,
  };
};

export default useUpdateIntegrationData;
