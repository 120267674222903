import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";

import classNames from "classnames";
import { CHANNELS } from "../../../../../constants/channels";
import search from "../../../../../assets/img/search.png";
import { UserChat } from "../../../../../components/UserChat";
import { getChannelIcon } from "../../../../../utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import supabase from "../../../../../supabase";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../../../store/slices/authSlice";
import {
  fetchInstructions,
  setSelectedAssistants,
  _selectedAssistantId,
  _updateListOfAssistants,
} from "../../../../../store/slices/assistantSlice";

import {
  fetchChatList,
  fetchChatListItem,
  fetchChatListNeedOperator,
} from "../../supabaseRequests";
import { FaChevronRight } from "react-icons/fa";
import { useChatContext } from "../../context/ChatContextProvider";
import { Input } from "../../../../../components/Input";

import styles from "./ChatList.styles.module.scss";

const ChatList = () => {
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const selectedAssistants = useSelector(
    (state) => state.assistant.selectedAssistants,
  );
  const selectedAssistantId = useSelector(_selectedAssistantId);
  const updateListOfAssistants = useSelector(_updateListOfAssistants);

  const { chatPartnerId } = useParams();
  const {
    activeTab,
    setActiveTab,
    isNeedUpdateChatList,
    setIsNeedUpdateChatList,
  } = useChatContext();

  const [isShowChatsList, setIsShowChatsList] = useState(false);
  const [buttonFilterChatsList, setButtonFilterChatsList] = useState("All");
  const [searchValue, setSearchValue] = useState("");
  const [unreadCount, setUnreadCount] = useState(0);

  const [chats, setChats] = useState();
  const [totalChatCount, setTotalChatCount] = useState(0);
  const [page, setPage] = useState(1);

  const accountChatsRef = useRef(null);
  const intervalRef = useRef(null);
  const firstRender = useRef(true);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const unread_needs_operator = useMemo(
    () => chats?.filter((el) => el.needs_operator && el.unread_count).length,
    [chats],
  );

  const handleClickChat = async (chatPartner) => {
    clearInterval(intervalRef.current);
    if (chatPartner.unread_count)
      await supabase
        .from("chats")
        .update({ unread_count: 0 })
        .match({ id: chatPartner.id });
    setIsShowChatsList(false);
    navigate(chatPartner.client_id.toString());
  };

  const getColor = (id) => {
    return updateListOfAssistants.find(({ value }) => value === id)?.bgColor;
  };

  const getChatList = useCallback(
    async (needOperator) => {
      try {
        const {
          data: chatsData,
          count,
          error,
        } = needOperator
          ? await fetchChatListNeedOperator(
              user.id,
              page,
              searchValue,
              buttonFilterChatsList,
            )
          : await fetchChatList(
              user.id,
              page,
              searchValue,
              buttonFilterChatsList,
            );

        if (error) {
          toast.error("Error search data:", error);
          return;
        }

        if (
          chatPartnerId &&
          buttonFilterChatsList === "All" &&
          activeTab === 0
        ) {
          const { data: activeChatData, error: activeChatError } =
            await fetchChatListItem(user.id, chatPartnerId);

          if (activeChatError) {
            toast.error("Error fetching active chat:", activeChatError);
          } else if (activeChatData) {
            const isActiveChatIncluded = chatsData.some(
              (chat) => chat.client_id === chatPartnerId,
            );

            if (!isActiveChatIncluded) {
              chatsData.unshift(activeChatData);
            }
          }
        }

        if (selectedAssistantId.length) {
          const items = chatsData.filter(({ assistant_id }) =>
            selectedAssistantId.includes(assistant_id),
          );
          setChats(items);
          setTotalChatCount(items.length);
          return;
        }
        setChats(chatsData);
        setTotalChatCount(count);
      } catch (error) {
        //toast.error("Error fetching data:", error);
      }
    },
    [
      activeTab,
      buttonFilterChatsList,
      chatPartnerId,
      page,
      searchValue,
      user.id,
      selectedAssistantId,
    ],
  );

  useEffect(() => {
    intervalRef.current && clearInterval(intervalRef.current);
    activeTab === 1 ? getChatList(true) : getChatList();
    intervalRef.current = setInterval(() => {
      activeTab === 1 ? getChatList(true) : getChatList();
    }, 15000);

    return () => intervalRef.current && clearInterval(intervalRef.current);
  }, [activeTab, getChatList]);

  useEffect(() => {
    if (isNeedUpdateChatList) {
      getChatList();
      setIsNeedUpdateChatList(false);
    }
  }, [isNeedUpdateChatList, getChatList, setIsNeedUpdateChatList]);

  useEffect(() => {
    if (chats) {
      setUnreadCount(
        chats?.reduce((acc, { unread_count }) => acc + unread_count, 0),
      );

      if ((firstRender.current || pathname === "/chats") && !chatPartnerId) {
        navigate(chats[0]?.client_id.toString());
        firstRender.current = false;
      }
    }
  }, [chatPartnerId, chats, navigate, pathname]);

  useEffect(() => {
    dispatch(fetchInstructions(user.id));
  }, [user]);

  const handleMarkAllRead = async () => {
    await supabase
      .from("chats")
      .update({ unread_count: 0 })
      .match({ account_id: user.id });
    setIsNeedUpdateChatList(true);
  };

  return (
    chats && (
      <>
        <div
          className={classNames(
            styles.accountChats,
            isShowChatsList && styles.rotated,
          )}
          ref={accountChatsRef}
        >
          <div className={styles.chatHeader}>
            <div className={`${styles.search} my-row`}>
              <Input
                isMulti={true}
                typeInput={"select"}
                placeholder={"Select an assistant"}
                options={updateListOfAssistants}
                value={selectedAssistants}
                onChange={(selectedOption) =>
                  dispatch(setSelectedAssistants(selectedOption))
                }
              />
            </div>
            <div className={styles.chatSearchList}>
              <button
                className={`${styles.chatSearchItem} ${
                  buttonFilterChatsList === "All" ? styles.active : ""
                }`}
                onClick={() => {
                  setButtonFilterChatsList("All");
                }}
              >
                All
              </button>
              {CHANNELS.map(({ icon, value }, index) => (
                <button
                  key={index}
                  className={`${styles.chatSearchItem} ${
                    buttonFilterChatsList === value ? styles.active : ""
                  }`}
                  onClick={() => {
                    setButtonFilterChatsList(value);
                  }}
                >
                  {icon}
                </button>
              ))}
            </div>
            <div className={styles.search}>
              <input
                className={styles.searchInput}
                placeholder={"Search"}
                value={searchValue}
                onChange={({ target: { value } }) => setSearchValue(value)}
              />
              <div className={styles.searchIcon}>
                <img src={search} alt={""} />
              </div>
            </div>
          </div>
          <div className={styles.chatList}>
            <div className={styles.tabs}>
              <div
                className={classNames(
                  styles.tab,
                  activeTab === 0 && styles.active,
                )}
              >
                <button
                  onClick={() => {
                    setActiveTab(0);
                  }}
                >
                  All chats
                </button>
                {!!unreadCount && (
                  <>
                    <button
                      className={styles.tabCount}
                      onClick={handleMarkAllRead}
                    >
                      <span className={styles.unreadCount}>{unreadCount}</span>
                      <span className={styles.tooltip}>Read All</span>
                    </button>
                  </>
                )}
              </div>
              <div className={styles.operator}>
                <button
                  className={classNames(
                    styles.tab,
                    activeTab === 1 && styles.active,
                  )}
                  onClick={() => {
                    setActiveTab(1);
                  }}
                >
                  {!!unread_needs_operator && (
                    <div className={styles.tabCount}>
                      {unread_needs_operator}
                    </div>
                  )}
                  Need operator
                </button>
                {/* {!!unread_needs_operator && (
                  <div className={styles.unread}>
                    <span className={styles.quantity}>
                      {unread_needs_operator}
                    </span>
                  </div>
                )} */}
              </div>
            </div>
            <div className={styles.chatListItems}>
              {chats.length > 0 ? (
                chats.map((chatItem, index) => (
                  <UserChat
                    onClick={() => handleClickChat(chatItem)}
                    key={index}
                    pageID={chatItem.page_id}
                    assistant_id={chatItem.assistant_id}
                    bgColor={getColor(chatItem.assistant_id)}
                    connection={getChannelIcon(chatItem?.communication_channel)}
                    full_name={
                      chatItem.client_name ||
                      chatItem.client_username ||
                      chatItem.client_id
                    }
                    avatar_url={chatItem?.client_avatar_url}
                    last_active={chatItem?.last_active}
                    is_selected={chatItem?.client_id === chatPartnerId}
                    unread={chatItem.unread_count}
                  />
                ))
              ) : (
                <div className={styles.emptyData}></div>
              )}
              {!searchValue && totalChatCount !== chats.length && (
                <button
                  className={styles.more}
                  onClick={() => setPage((prev) => prev + 1)}
                >
                  more...
                </button>
              )}
            </div>
          </div>
        </div>

        <div className={styles.toggle}>
          <button
            className={classNames(
              styles.btn,
              isShowChatsList && styles.rotated,
            )}
            onClick={() => setIsShowChatsList(!isShowChatsList)}
          >
            <FaChevronRight size={30} />
          </button>
        </div>
        {!chatPartnerId && (
          <div className={styles.preloadMessageList}>
            <p>Please choose any chat</p>
          </div>
        )}
      </>
    )
  );
};

export default ChatList;
