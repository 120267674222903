import React from "react";
import styles from "./MessageItem.styles.module.scss";
import { processMessageContent } from "../../../../helpers";
import { Manager, Viber } from "../../../../../../../assets/icons";
import { FaBookOpen } from "react-icons/fa";
import { formatDate } from "../../../../../../../utils";
import Knowledge from "../Knowledge";
import classNames from "classnames";
import { MdToHTML } from "../../../../../../../components/MdToHTML";
import { ReplyMessage } from "../ReplyMessage/ReplyMessage";
import { MediaRenderer } from "../MediaRenderer/MediaRenderer";
import "video.js/dist/video-js.css";

const MessageItem = ({
  knowledgeHints,
  message,
  isSending,
  handleClickSpecMessageSave,
  handleChangeSpecMessageTextarea,
  handleClickSpecMessageIcon,
}) => {
  const { content, images, instagramStoryLink, media, postContent } =
    processMessageContent(message);

  const manageStr = "Manager: ";
  const viberStr = "Viber: ";
  const isManagerStr = content.includes(manageStr);
  const isViberStr = content.includes(viberStr);

  const newContent = isManagerStr
    ? content.substring(manageStr.length).trim()
    : isViberStr
    ? content.substring(viberStr.length).trim()
    : content;

  // Extract reply message if present (supports both text and image replies)
  let replyToMessage = null;
  let cleanedContent = newContent;

  if (message?.MultiContent) {
    const replyItem = message.MultiContent.find(
      (item) =>
        item.type === "text" && item.text && item.text.includes("Reply to:")
    );
    if (replyItem) {
      const replyText = replyItem.text.split("Reply to: ")[1].trim();
      replyToMessage = replyText;
      cleanedContent = cleanedContent.replace(replyItem.text, "").trim();
    }
  } else {
    const replyToIndex = newContent.indexOf("Reply to:");
    if (replyToIndex !== -1) {
      replyToMessage = newContent.substring(replyToIndex + 9).trim();
      cleanedContent = newContent.substring(0, replyToIndex).trim();
    }
  }

  const playerRef = React.useRef(null);

  const handlePlayerReady = (player) => {
    playerRef.current = player;
  };

  return (
    <div className={styles.containerMessage}>
      <div className={`${styles.message} ${styles[message.role]}`}>
        {/* If this is a reply to another message */}
        {replyToMessage && <ReplyMessage originalMessage={replyToMessage} />}

        {isManagerStr && (
          <div className={styles.sentVia}>
            <Manager />
          </div>
        )}

        {isViberStr && (
          <div className={styles.sentVia}>
            <Viber />
          </div>
        )}

        {cleanedContent && <MdToHTML content={cleanedContent} />}

        {instagramStoryLink && (
          <iframe
            alt=""
            src={instagramStoryLink}
            style={{ height: "400px" }}
            frameBorder="0"
            rel="instagram story"
            title="instagram story"
          ></iframe>
        )}

        {postContent && (
          <div className={styles.postContent}>
            <p>{postContent}</p>
          </div>
        )}

        {images?.length > 0 &&
          images?.map((image, index) => (
            <img
              key={index}
              alt=""
              src={image}
              onLoad={(e) => {
                const { naturalWidth, naturalHeight } = e.target;
                if (naturalWidth === 1 && naturalHeight === 1) {
                  e.target.style.display = "none";
                }
              }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src =
                  "https://user-images.githubusercontent.com/20684618/31289519-9ebdbe1a-aae6-11e7-8f82-bf794fdd9d1a.png";
              }}
              style={{
                marginTop: "3px",
                maxHeight: "450px",
                maxWidth: "415px",
                objectFit: "contain",
                background: "#e5e5ea",
              }}
              target="_blank"
              rel="image"
            />
          ))}

        {media && media.length > 0 && (
          <div className={styles.mediaContainer}>
            {media.map((mediaUrl, index) => {
              const videoJsOptions = {
                autoplay: false,
                controls: true,
                responsive: true,
                fluid: true,
                sources: [
                  {
                    src: mediaUrl,
                    type: "video/mp4",
                  },
                ],
              };

              return (
                <div key={index} className={styles.mediaWrapper}>
                  <MediaRenderer
                    options={videoJsOptions}
                    onReady={handlePlayerReady}
                  />
                </div>
              );
            })}
          </div>
        )}

        <FaBookOpen
          size={24}
          className={styles.specMessageIcon}
          title="Click to improve answer"
          onClick={() => handleClickSpecMessageIcon(message.id)}
        />
      </div>

      <div className={classNames(styles.messageDate, styles[message.role])}>
        {formatDate(message?.created_at)}
      </div>

      <Knowledge
        knowledgeHints={knowledgeHints}
        message={message}
        isSending={isSending}
        handleClickSpecMessageSave={handleClickSpecMessageSave}
        handleChangeSpecMessageTextarea={handleChangeSpecMessageTextarea}
      />
    </div>
  );
};

export default MessageItem;
