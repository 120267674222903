import { useState, useCallback } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import supabase from "../../../../../../supabase";
import {
  fetchIntegrations,
  selectSelectedAssistant,
  selectSelectedIntegrationsIdsByType,
  selectSelectedIntegrationType,
} from "../../../../../../store/slices/integrationSlice";
import {
  useIntegrationTokenMutation,
  useValidateIntegrationAssetsMutation,
} from "../../../../../../store/api";
import useUpdateAssistantIntegrations from "../../../../useUpdateAssistantIntegrations";
import useUpdateIntegrationData from "../../../../useUpdateIntegrationData";
import { fetchInstructions as fetchAssistants } from "../../../../../../store/slices/assistantSlice";
import { useLoadingContext } from "../../../../useLoadingContext";
import { useIntegrationChecks } from "../useIntegrationChecks";
import { INTEGRATION_CONFIG } from "../../../../../../constants/integrations";
import { sanitizeDomain } from "../../../../../../helper";

const useKeyConnectionData = () => {
  const { isLoading, setIsLoading } = useLoadingContext();

  const [integrationFields, setIntegrationFields] = useState({});

  const selectedAssistant = useSelector(selectSelectedAssistant);
  const user = useSelector((state) => state.auth.user);
  const { addAssistantIntegration, removeAssistantIntegration } =
    useUpdateAssistantIntegrations();
  const { deleteIntegration } = useUpdateIntegrationData();
  const selectedIntegrationType = useSelector(selectSelectedIntegrationType);
  const selectedIntegrationName = selectedIntegrationType?.name;
  const selectedIntegrationsIdsByType = useSelector(
    selectSelectedIntegrationsIdsByType
  );
  const [integrationToken] = useIntegrationTokenMutation();
  const [validateIntegrationAssets] = useValidateIntegrationAssetsMutation();
  const dispatch = useDispatch();
  const { isAuthTypeApiRefreshToken, isBitrix } = useIntegrationChecks();

  const getFieldsForIntegration = useCallback(
    (integrationName) => {
      return (
        (integrationFields[selectedAssistant.id] &&
          integrationFields[selectedAssistant.id][integrationName]) ||
        {}
      );
    },
    [integrationFields, selectedAssistant.id]
  );

  const updateField = (integrationName, fieldName, value) => {
    setIntegrationFields((prev) => ({
      ...prev,
      [selectedAssistant.id]: {
        ...(prev[selectedAssistant.id] || {}),
        [integrationName]: {
          ...(prev[selectedAssistant.id]?.[integrationName] || {}),
          [fieldName]: value,
        },
      },
    }));
  };

  const resetFieldsForIntegration = useCallback(
    (integrationName) => {
      setIntegrationFields((prev) => ({
        ...prev,
        [selectedAssistant.id]: {
          ...(prev[selectedAssistant.id] || {}),
          [integrationName]: {},
        },
      }));
    },
    [setIntegrationFields]
  );

  const createConnection = useCallback(async () => {
    try {
      setIsLoading(true);

      const fields = getFieldsForIntegration(selectedIntegrationName);
      const config =
        INTEGRATION_CONFIG[selectedIntegrationName] ||
        INTEGRATION_CONFIG.default;

      // Check if all required fields are filled
      const missingFields = config.fields.filter((field) => !fields[field]);
      if (missingFields.length > 0) {
        toast.error(
          `Please, fill all required fields: ${missingFields.join(", ")}`
        );
        setIsLoading(false);
        return;
      }

      const { clientId, clientSecret, domain } = fields;

      // Check for existing Bitrix integration
      let integrationId = null;

      if (isBitrix) {
        // Query Supabase for existing integrations with the same metadata
        const { data: existingIntegrations, error } = await supabase
          .from("integrations")
          .select("*")
          .eq("account_id", user.id)
          .eq("integration", "Bitrix")
          .eq("metadata->>client_id", clientId)
          .eq("metadata->>client_secret", clientSecret)
          .eq("metadata->>domain", domain);

        if (error) {
          console.error("Error fetching existing integrations:", error);
          toast.error("Error checking for existing integrations");
          setIsLoading(false);
          return;
        } else if (existingIntegrations && existingIntegrations.length > 0) {
          // Use existing integration
          integrationId = existingIntegrations[0].id;
        } else {
          // Create new integration with placeholder tokens
          const requestData = {
            account_id: user.id,
            integration: selectedIntegrationName,
            ...config.mapFields(fields),
          };

          const integrationRes = await integrationToken(requestData);

          if (integrationRes.error) {
            toast.error(integrationRes.error);
            setIsLoading(false);
            return;
          }
        }

        // Redirect to Bitrix OAuth URL
        const redirectUri = encodeURIComponent(
          "https://app.mychatbot.app/integration?integration=Bitrix"
        );
        const sanitizedDomain = sanitizeDomain(domain);
        const state = encodeURIComponent(`${user.id}|${selectedAssistant.id}`);

        const bitrixAuthUrl = `${sanitizedDomain}/oauth/authorize/?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}`;

        window.location.href = bitrixAuthUrl;
        return;
      }

      // For other integrations, proceed with validation and connecting the assistant
      const requestData = {
        account_id: user.id,
        integration: selectedIntegrationName,
        ...config.mapFields(fields),
      };

      const integrationRes = await integrationToken(requestData);

      if (integrationRes.error) {
        toast.error(integrationRes.error);
        setIsLoading(false);
        return;
      }

      const validateIntegrationRes = await validateIntegrationAssets(
        requestData
      );

      if (validateIntegrationRes.error) {
        await deleteIntegration(integrationRes.data.id);
        toast.error(validateIntegrationRes.error.data.error);
      } else {
        const currentAssistantIntegrationId =
          selectedAssistant?.integrations?.find((id) =>
            selectedIntegrationsIdsByType.includes(id)
          );

        let updatedIntegrations = null;

        if (currentAssistantIntegrationId) {
          updatedIntegrations = await removeAssistantIntegration(
            currentAssistantIntegrationId
          );
        }
        await addAssistantIntegration(
          integrationRes.data.id,
          updatedIntegrations
        );

        resetFieldsForIntegration(selectedIntegrationName);
      }
    } catch (error) {
      toast.error(error?.data?.error || error?.message || `Error: ${error}`);
    } finally {
      await dispatch(fetchIntegrations());
      await dispatch(fetchAssistants());
      setIsLoading(false);
    }
  }, [
    setIsLoading,
    getFieldsForIntegration,
    selectedIntegrationName,
    isBitrix,
    integrationToken,
    selectedAssistant,
    dispatch,
    fetchIntegrations,
    fetchAssistants,
    user.id,
  ]);

  return {
    createConnection,
    isLoading,
    integrationFields,
    updateField,
    getFieldsForIntegration,
    resetFieldsForIntegration,
    setIntegrationFields,
  };
};

export default useKeyConnectionData;
