import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import {
  authSlice,
  onboardingSlice,
  assistantSlice,
  followUpsSlice,
  knowledgeBaseSlice,
  integrationSlice,
  notificationsSlice,
  disableBodySlice,
  timePickerSlice,
} from "./slices";
import {
  aiConfigurationApi,
  assistantApi,
  channelsApi,
  integrationApi,
  chatsApi,
  followUpsApi,
  referralProgramApi,
} from "./api";

export const store = configureStore({
  reducer: {
    [aiConfigurationApi.reducerPath]: aiConfigurationApi.reducer,
    [channelsApi.reducerPath]: channelsApi.reducer,
    [assistantApi.reducerPath]: assistantApi.reducer,
    [integrationApi.reducerPath]: integrationApi.reducer,
    [chatsApi.reducerPath]: chatsApi.reducer,
    [referralProgramApi.reducerPath]: referralProgramApi.reducer,
    timePicker: timePickerSlice,
    disableBody: disableBodySlice,
    auth: authSlice,
    onboarding: onboardingSlice,
    assistant: assistantSlice,
    followUps: followUpsSlice,
    [followUpsApi.reducerPath]: followUpsApi.reducer,
    knowledgeBase: knowledgeBaseSlice,
    integration: integrationSlice,
    notifications: notificationsSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      aiConfigurationApi.middleware,
      channelsApi.middleware,
      assistantApi.middleware,
      integrationApi.middleware,
      chatsApi.middleware,
      followUpsApi.middleware,
      referralProgramApi.middleware
    ),
});

setupListeners(store.dispatch);
