import styles from "./ClientCard.module.scss";
import ActionMenu from "./components/ActionMenu";
import { IoIosRefresh } from "react-icons/io";
import { useCallback, useEffect, useRef, useState } from "react";
import { FaChevronLeft } from "react-icons/fa";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectUser } from "../../../../../store/slices/authSlice";
import { useResetChatHistoryMutation } from "../../../../../store/api";
import supabase from "../../../../../supabase";
import ClientCardInfo from "./components/ClientCardInfo";
import ClientCardForm from "./components/ClientCardForm";
import { useChatContext } from "../../context/ChatContextProvider";

const ClientCard = () => {
  const user = useSelector(selectUser);
  const { chatPartnerId } = useParams();

  const [clientCardData, setClientCardData] = useState(null);
  const { setIsNeedUpdateChatList } = useChatContext();

  const [resetChatHistory] = useResetChatHistoryMutation();
  const [isShowClientCard, setIsShowClientCard] = useState(false);
  const [loading, setLoading] = useState(false);

  const containerRef = useRef(null);

  const getClientData = async () => {
    if (!user?.id || !chatPartnerId) return;

    try {
      const { data } = await supabase
        .from("clients")
        .select("*")
        .eq("account_id", user.id)
        .eq("client_id", chatPartnerId)
        .single();

      setClientCardData(data || null); // Set data if available, otherwise null
    } catch (error) {
      setClientCardData(null); // Handle error by setting data to null
    }
  };

  const getClientDataWithLoading = useCallback(async () => {
    setLoading(true);
    await getClientData();
    setLoading(false);
  }, [chatPartnerId, user?.id]);

  const options = [
    {
      label: "Reset user input flow",
      action: () => {
        resetChatHistory({
          account_id: clientCardData.account_id,
          client_id: clientCardData.client_id,
        });
        setTimeout(() => {
          setIsNeedUpdateChatList(true);
        }, 500);
      },
      icon: <IoIosRefresh size={16} className={styles.icon} />,
    },
  ];

  useEffect(() => {
    if (chatPartnerId && user) {
      // Fetch client data immediately
      getClientDataWithLoading();

      // Refresh client data
      const intervalId = setInterval(async () => {
        await getClientData();
      }, 20000); // 20 seconds interval

      // Cleanup interval
      return () => clearInterval(intervalId);
    } else {
      setClientCardData(null);
    }
  }, [chatPartnerId, user]);

  return (
    <>
      <div className={styles.toggle}>
        <button
          className={classNames(styles.btn, {
            [styles.rotated]: isShowClientCard,
          })}
          onClick={() => setIsShowClientCard(!isShowClientCard)}
        >
          <FaChevronLeft size={30} />
        </button>
      </div>

      {loading ? (
        <div
          className={classNames(styles.container, {
            [styles.hide]: !isShowClientCard,
            [styles.loading]: true,
          })}
        >
          <p>Loading User Profile...</p>
        </div>
      ) : (
        clientCardData && (
          <div
            className={classNames(styles.container, {
              [styles.hide]: !isShowClientCard,
            })}
            ref={containerRef}
          >
            <div className={styles.header}>
              <h2>{clientCardData.full_name}</h2>
              <ActionMenu options={options} />
            </div>
            <div className={styles.content}>
              <ClientCardForm
                clientCardData={clientCardData}
                updateClientData={getClientData}
              />
              <div className={styles.divider}></div>
              <ClientCardInfo clientCardData={clientCardData} />
            </div>
          </div>
        )
      )}
    </>
  );
};

export default ClientCard;
