import { useCallback, useMemo, useState } from "react";
import classNames from "classnames";
import { Modal } from "../../../../../../components/Modal";
import CustomFollowUpDropdown from "../../../CustomFollowUpDropdown/CustomFollowUpDropdown";
import { ANALYSIS_DEPTH_OPTIONS } from "../../const";
import { Button } from "../../../../../../components/Button";
import { useFollowUpPageContext } from "../../../../context/useFollowUpPageContext";

import styles from "./EditNodeModal.module.scss";

const ANALYSIS_DEPTH_LABELS = ANALYSIS_DEPTH_OPTIONS.map(
  (option) => option.label
);

export function EditNodeModal() {
  const {
    isEditNodeModalOpen,
    setIsEditNodeModalOpen,
    updateNodeData,
    selectedNodeId,
    nodes,
  } = useFollowUpPageContext();
  const node = useMemo(
    () => nodes.find((node) => node.id === selectedNodeId),
    [nodes, selectedNodeId]
  );

  const [nodeName, setNodeName] = useState(node?.data?.label || "");
  const [nodePrompt, setNodePrompt] = useState(node?.data?.prompt || "");
  const [nodeDepth, setNodeDepth] = useState(node?.data?.depth || 6);

  const handleOnSave = useCallback(() => {
    updateNodeData({
      nodeName,
      nodePrompt,
      nodeDepth,
    });
    setIsEditNodeModalOpen(false);
  }, [updateNodeData, nodeName, nodePrompt, nodeDepth, setIsEditNodeModalOpen]);

  return (
    <Modal
      isShowModal={isEditNodeModalOpen}
      title="Edit action"
      contentClassName={styles.modalContent}
      setIsShowModal={setIsEditNodeModalOpen}
      actions={
        <div className={styles.actionsWrapper}>
          <label className={styles.label}>
            Name:
            <input
              placeholder="Next"
              className={styles.input}
              onChange={(e) => {
                setNodeName(e.target.value);
              }}
              defaultValue={node?.data?.label}
            />
          </label>
          <label className={styles.label}>
            Prompt:
            <textarea
              placeholder="Text"
              className={classNames(styles.input, styles.textarea)}
              onChange={(e) => {
                setNodePrompt(e.target.value);
              }}
              defaultValue={node?.data?.prompt}
            />
          </label>
          <label className={classNames(styles.label, styles.selectLabel)}>
            Chat context depth:
          </label>
          <CustomFollowUpDropdown
            options={ANALYSIS_DEPTH_LABELS}
            placeholder={node?.data?.depth || "Last 6 messages"}
            onChange={(newValue) => {
              setNodeDepth(newValue);
            }}
          />
          <Button
            title="Save"
            variant="contained"
            className={styles.saveButton}
            onClick={handleOnSave}
          />
        </div>
      }
    />
  );
}
