import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useSubscriptionMutation, useUpgradeMutation } from "../../store/api";
import supabase from "../../supabase";
import { Subscription } from "../../components/Subscription";
import { Loader } from "../../components/Loader";
import { Title } from "../../components/Title";
import styles from "./Subscription.module.scss";

import { SUBSCRIPTIONS } from "../../constants/subscription";
import { setOnboarding } from "../../store/slices/onboardingSlice";
import { SuccessSubscriptionUpdate } from "../../components/popups/SuccessSubscriptionUpdate/SuccessSubscriptionUpdate";
import { normalizeSubscriptionPlan } from "../../helper";

export const SubscriptionPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const step = useSelector((state) => state.onboarding.step);

  const [isShowModal, setIsShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [subscription, setSubscription] = useState("");
  const [userSubscription, setUserSubscription] = useState(null);
  const [highlightMultipro, setHighlightMultipro] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);

  const [cancelSubscription] = useSubscriptionMutation();
  const [upgradeSubscription] = useUpgradeMutation();

  const normalizedPlan = userSubscription
    ? normalizeSubscriptionPlan(userSubscription.subscription_plan)
    : null;

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const highlightPlan = queryParams.get("highlight");
    if (highlightPlan === "multipro") {
      setHighlightMultipro(true);
    }

    if (queryParams.get("subscription") === "success") {
      setIsLoading(true);
      fetchAndSetSubscription();
      toast.success("Subscription was created successfully.");
      setIsSuccessPopupOpen(true);

      const newUrl = window.location.pathname;
      window.history.replaceState({}, document.title, newUrl);
    }
  }, [location.search]);

  const fetchAndSetSubscription = async () => {
    try {
      setIsLoading(true);
      const { data } = await supabase
        .from("subscriptions")
        .select("*")
        .eq("email", user.email);

      if (data.length !== 0) {
        setUserSubscription(data[0]);
        if (step === 3) {
          await supabase
            .from("onboardings")
            .update({ step: 4 })
            .match({ account_id: user.id });
          dispatch(setOnboarding(4));
        }
      }
    } catch (error) {
      setIsLoading(false);
      setIsShowModal(true);
      setErrorText(error.data);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setIsLoading(false);
        const urlWithoutSuccess = window.location.pathname;
        const newUrl = `${urlWithoutSuccess}${window.location.search.replace(
          "?success=true",
          ""
        )}`;
        window.history.replaceState({}, document.title, newUrl);
      }, 10000);
    }
  };

  const cancelPlan = async () => {
    var baseURL =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    var newURL = baseURL + "?success=true";
    try {
      setIsLoading(true);
      await cancelSubscription({ currentSubscription: subscription });
    } catch (error) {
      setIsLoading(false);
      setIsShowModal(true);
      setErrorText(error.data);
    } finally {
      window.location.href = newURL;
    }
  };

  const upgradePlan = async (plan) => {
    var baseURL =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    var newURL = baseURL + "?success=true";
    try {
      setIsLoading(true);
      await upgradeSubscription({
        currentSubscription: subscription,
        plan: plan,
      });
    } catch (error) {
      setIsLoading(false);
      setIsShowModal(true);
      setErrorText(error.data);
    } finally {
      window.location.href = newURL;
    }
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.get("success") === "true") {
      setIsLoading(true);
      fetchAndSetSubscription();
    }
  }, [location.search]);

  useEffect(() => {
    fetchAndSetSubscription();
  }, []);

  const Modal = ({ isOpen, children }) => {
    if (!isOpen) return null;

    return (
      <div className={styles.modalOpen}>
        <div className={styles.modalContent}>{children}</div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <Title title={"Upgrade your Plan"} />
        <p className={styles.subTitle}>
          Unlock advanced features and elevate your creative potential by
          upgrading to Pro or Business. Gain access to unlimited tools, priority
          support, and enhanced performance to supercharge your projects and
          workflows.
        </p>

        <div className={styles.integrations}>
          {SUBSCRIPTIONS.map((planData, index) => {
            const isCurrentPlan =
              normalizedPlan === planData.title.toLowerCase();

            const isHighlighted =
              isCurrentPlan ||
              (highlightMultipro &&
                planData.title.toLowerCase() === "multipro");

            return (
              <Subscription
                key={index}
                planData={planData}
                userSubscription={userSubscription}
                cancelPlan={cancelPlan}
                upgradePlan={upgradePlan}
                highlight={isHighlighted}
                isLoading={isLoading}
              />
            );
          })}
        </div>

        {/*subscription && <Button
                    onClick={
                        () => window.location.href = process.env.REACT_APP_PADDLE_BUYER_PORTAL_URL + "/subscriptions/" + subscription.subscription_id + "/update-payment-method?token=" + process.env.REACT_APP_PADDLE_AUTH_CODE
                    }
                    className={styles.paymentButton}
                    title='Update payment details'
                />*/}
      </div>

      <Modal isOpen={isLoading}>
        <div className={styles.loaderContainer}>
          <div className={styles.loaderText}>Processing...</div>
          {isLoading && <Loader width={60} height={60} />}
        </div>
      </Modal>
      <Modal
        isShowModal={isShowModal}
        title={"Error"}
        description={errorText}
        setIsShowModal={setIsShowModal}
      />
      <SuccessSubscriptionUpdate
        isPopupOpen={isSuccessPopupOpen}
        setIsPopupOpen={setIsSuccessPopupOpen}
      />
    </div>
  );
};
