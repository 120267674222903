import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useSearchParams,
  useNavigate,
  createSearchParams,
} from "react-router-dom";
import axios from "axios";

import { Loader } from "../../components";
import { Modal } from "../../components/Modal";

import { ROUTES } from "../../constants/routes";

import styles from "./CheckOLXPage.module.scss";

export const CheckOLXPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user = useSelector((state) => state.auth.user);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorText, setErrorText] = useState("");

  const account_id = user.id;
  const code = searchParams.get("code");
  const country = searchParams.get("country");
  const olx_account_name = searchParams.get("account");
  const assistant_id_str = searchParams.get("assistant");
  const assistant_id = assistant_id_str ? parseInt(assistant_id_str) : 0;
  const redirect_uri = `${process.env.REACT_APP_DOMAIN}/oauth/olx?account=${olx_account_name}&country=${country}&assistant=${assistant_id_str}`;

  const sendData = async () => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_API_URL}/oauth/olx/${account_id}`,
        {
          code,
          redirect_uri,
          olx_account_name,
          country,
          assistant_id,
        },
      );

      if (data.status === 200) {
        navigate({
          pathname: ROUTES.channelsPage,
          search: createSearchParams({
            tab: "OLX Account",
            subtab: olx_account_name,
          }).toString(),
        });
      }
    } catch (error) {
      setErrorText(error.message);
      setIsModalVisible(true);
    }
  };

  useEffect(() => {
    sendData();
  }, []);

  const handleCloseModal = () => {
    setIsModalVisible(false);
    navigate({
      pathname: ROUTES.channelsPage,
      search: createSearchParams({
        tab: "OLX Account",
        subtab: "new",
      }).toString(),
    });
  };

  return !isModalVisible ? (
    <div className={styles.loaderContainer}>
      <Loader width={80} height={80} />
    </div>
  ) : (
    <Modal
      title={"Error"}
      description={errorText}
      isShowModal={isModalVisible}
      setIsShowModal={handleCloseModal}
    />
  );
};
