import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { ControlledPopup } from "../../Popup";
import { Button } from "../../Button";
import { getAFormOfPayment } from "../../../service/mychatbot.js";
import styles from "./PopupPayment.module.scss";

export const PopupPayment = ({
  isOpenPopup,
  setIsOpenPopup,
  userId,
  tokenPice,
}) => {
  const [htmlString, setHtmlString] = useState("");
  const [quantityTokens, setQuantityTokens] = useState(1);
  const [price, setPrice] = useState(0);
  const [disabled, setDisabled] = useState(false);

  const wFormRef = useRef(null);

  const closePopup = () => {
    setIsOpenPopup(false);
  };

  const onProceedToPaymentClick = async () => {
    setDisabled(true);
    await addAForm();
    setTimeout(() => {
      wFormRef.current.querySelector(".wfpform").submit();
    }, 0);
  };

  const onButtonSelect = (value) => {
    setQuantityTokens(value);
  };

  const addAForm = async () => {
    try {
      const formMarkup = await getAFormOfPayment({
        account: userId,
        amount: quantityTokens,
      });
      setHtmlString(formMarkup);
      return formMarkup;
    } catch (error) {
      toast.error(error);
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (!isOpenPopup) return setHtmlString("");
  }, [isOpenPopup, userId]);

  useEffect(() => {
    if (!tokenPice) return;
    setPrice(quantityTokens * tokenPice);
  }, [tokenPice, quantityTokens]);

  return (
    <>
      <div
        className={styles.wForm}
        dangerouslySetInnerHTML={{ __html: htmlString }}
        ref={wFormRef}
      ></div>
      <ControlledPopup
        isOpenPopup={isOpenPopup}
        closePopup={closePopup}
        actions={
          <div className={styles.form}>
            <div className={styles.wInput}>
              <label className={styles.iLabel}>AI Messsages</label>
              <div className={styles.buttonGroup}>
                {[1, 2, 5, 10, 20].map((value) => (
                  <button
                    key={value}
                    disabled={disabled}
                    className={`${styles.selectButton} ${
                      quantityTokens === value ? styles.active : ""
                    }`}
                    onClick={() => onButtonSelect(value)}
                  >
                    {value * 100}
                  </button>
                ))}
              </div>
            </div>
            <div className={styles.formPrice}>
              Total cost for {quantityTokens * 100} AI Messsages would be $
              {price}
            </div>
            <Button
              className={styles.proceedToPaymentButton}
              onClick={onProceedToPaymentClick}
              title={"Proceed to payment"}
              disabled={disabled}
            />
          </div>
        }
      />
    </>
  );
};
