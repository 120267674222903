import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Button, Input, Loader, Typography } from "../../../../components";
import { useSendTokensMutation } from "../../../../store/api";
import { EMAIL_REGEX } from "../../const";
import { selectUser } from "../../../../store/slices/authSlice";

import styles from "./SendTokens.module.scss";

const SendTokens = () => {
  const [emailInput, setEmailInput] = useState("");
  const [amountInput, setAmountInput] = useState("");
  const [emailHasError, setEmailHasError] = useState(false);
  const [amountHasError, setAmountHasError] = useState(false);

  const [sendTokens, { isLoading }] = useSendTokensMutation();
  const user = useSelector(selectUser);

  const handleEmailChange = useCallback((e) => {
    setEmailHasError(false);
    setEmailInput(e.target.value);
  }, []);

  const handleAmountChange = useCallback((e) => {
    setAmountHasError(false);
    setAmountInput(e.target.value);
  }, []);

  const handleSend = useCallback(async () => {
    if (!EMAIL_REGEX.test(emailInput)) {
      setEmailHasError(true);
      return;
    }

    if (!/^\d+$/.test(amountInput) || Number(amountInput) <= 0) {
      setAmountHasError(true);
      return;
    }

    try {
      await sendTokens({
        userId: user.id,
        email: emailInput,
        tokens: Number(amountInput) * 10000,
      });
      toast.success("Message credits sent successfully");
      setEmailInput("");
      setAmountInput("");
    } catch (error) {
      console.log(error);
      toast.error("Failed to send message credits");
    }
  }, [emailInput, amountInput, sendTokens, user.id]);

  return (
    <div className={styles.container}>
      <Typography variant="h5">
        Send message credits to another account
      </Typography>
      <Typography variant="body3" className={styles.description}>
        You can send message credits from your account to any other account. To
        do this, enter the recipient's email, specify the number of message
        credits, and click the send button.
      </Typography>
      <div className={styles.inputTitle}>
        Enter the email of the message credit recipient
      </div>
      <div className={styles.inputWrapper}>
        <Input
          placeholder="Enter email"
          type="email"
          value={emailInput}
          onChange={handleEmailChange}
          customClass={styles.input}
        />
        {emailHasError ? (
          <div className={styles.errorText}>Invalid email</div>
        ) : null}
      </div>
      <div className={styles.inputTitle}>
        Enter the number of message credits you wish to send
      </div>
      <div className={styles.inputTitle}>
        <Input
          placeholder="Enter amount"
          value={amountInput}
          onChange={handleAmountChange}
          customClass={styles.input}
          type="number"
        />
        {amountHasError ? (
          <div className={styles.errorText}>Invalid amount</div>
        ) : null}
      </div>
      {isLoading ? (
        <div className={styles.loaderContainer}>
          <Loader height={35} width={35} />
        </div>
      ) : (
        <Button
          variant="contained"
          title="Send"
          className={styles.submitButton}
          onClick={handleSend}
        />
      )}
    </div>
  );
};

export default SendTokens;
