import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const channelsApi = createApi({
    reducerPath: 'channelsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        credentials: 'include',
    }),
    endpoints: (builder) => ({
            validateToken: builder.mutation({
                query: (body) => ({
                    url: '/validate-token',
                    method: 'POST',
                    credentials: 'include',
                    body,
                }),
            }),
            setupWebhook: builder.mutation({
                query: (body) => ({
                    url: '/setup-webhook',
                    method: 'POST',
                    credentials: 'include',
                    body,
                }),
            }),
            longTermToken: builder.mutation({
                query: (body) => ({
                    url: '/oauth/long-term-token',
                    method: 'POST',
                    credentials: 'include',
                    body,
                }),
                //transformErrorResponse: (
                //    response,
                //    meta,
                //    arg
                //  ) => {
                //    console.log(response, meta, arg);
                //    return meta
                //},
            }),
            removeChannel: builder.mutation({
                query: (body) => ({
                    url: '/remove-channel',
                    method: 'DELETE',
                    credentials: 'include',
                    body,
                }),
            }),
        }
    ),
});

export const {
    useValidateTokenMutation,
    useSetupWebhookMutation,
    useLongTermTokenMutation,
    useRemoveChannelMutation,
} = channelsApi;
